import * as mobx from 'mobx'

export const toJS = (string) => {
  console.log('------')
  console.log(mobx.toJS(string))
  console.log('------')
}

export const t = (stringList, identifier, lang) => {
  const string = stringList.find((string) => string.identifier === identifier)
  if (!string) {
    return ''
  }
  return string['string'][lang]
}

export const getBreadcrumb = async (menuList, fullPath) => {
  const level1 = menuList.find((menu) => (menu.level === 1))
  const level2 = menuList.find((menu) => (menu.level === 2 && fullPath.includes(menu.path)))
  const level3 = menuList.find((menu) => (menu.level === 3 && fullPath.includes(menu.path)))
  return { level1, level2, level3 }
}

export const truncateString = (str, n, useWordBoundary) => {
  if (str.length <= n) { return str }
  const subString = str.substr(0, n - 1)
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString) + '...'
}

export const trimHtmlTag = (string) => {
  const regex = /(<([^>]+)>)/ig;
  const result = string.replace(regex, '');
  return result
}

export const storeLanguage = (language) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem('LANGUAGE', language)
  }
}

export const getLanguage = () => {
  if (typeof window !== "undefined") {
    const language = window.localStorage.getItem('LANGUAGE')
    if (!language) {
      if (navigator.language === 'zh-CN') {
        return 'sc'
      } else if (navigator.language === 'zh-TW' || navigator.language === 'zh-HK') {
        return 'tc'
      }
      return 'en'
    }
    return language
  }
}
