import React from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { toJS } from '@src/utils/commonUtil'
import { getPostDetailPath } from '@src/utils/hkpcaUtil'
import SearchIcon from '@material-ui/icons/Search'
import SearchField from '@hkpca-web/components/inputs/SearchField'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'

@inject('initDataStore')
@observer
@withTranslation()

class Layout extends React.Component {
  constructor (props) {
    super(props)
    const parsed = queryString.parse(window.location.search)
    let text = ''
    let id = ''
    if (parsed.text) {
      text = parsed.text
    }
    if (parsed.id) {
      id = parseInt(parsed.id)
    }
    this.state = {
      id: id,
      searchText: text,
      isReady: false
    }
  }

  routeToSearchResult = (text) => {
    // navigate()
    const url = `/search-result/?text=${text}`
    window.location.replace(url)
  }

  handleClick = (postId, postType, redirectUrl) => {
    const postDetailPath = getPostDetailPath(postType)
    const url = `${postDetailPath}?id=${postId}`
    this.setState({
      id: postId
    })
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    } else {
      if (this.state.id !== postId) {
        navigate(url)
      }
    }
  }

  render () {
    const { initDataStore, i18n } = this.props
    if (!initDataStore.latestPostLists) {
      return (<></>)
    }
    const newsList = initDataStore.latestPostLists.news
    const eventList = initDataStore.latestPostLists.event
    const marketInformationList = initDataStore.latestPostLists.marketInformation
    const { handleClick, routeToSearchResult } = this
    return (
      <div className='latest-post-list'>
        {/* <hr className='latest-post-top-line' /> */}
        <div className='search-bar'>
          <SearchField value={this.state.searchText} placeholder={i18n.t('other.searchPlaceholder')} iconName={'search'} action={routeToSearchResult} />
        </div>
        <div>
          <div className='category-title'>
            {
              i18n.t('other.latestNewsTitle')
            }
          </div>
          {
            newsList.map((post, key) => {
              return (
                <div className='post' key={key}>
                  <div className='title btn' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>{post.title[i18n.language]}</div>
                  <div className='date'>
                    {
                      post.displayDateTime &&
                      moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                    }
                  </div>
                </div>
              )
            })
          }
          <hr />
          <div className='category-title'>
            {
              i18n.t('other.latestEventTitle')
            }
          </div>
          {
            eventList.map((post, key) => {
              return (
                <div className='post' key={key}>
                  <div className='title btn' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>{post.title[i18n.language]}</div>
                  <div className='date'>
                    {
                      post.displayDateTime &&
                      moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                    }
                  </div>
                </div>
              )
            })
          }
          <hr />
          <div className='category-title'>
            {
              i18n.t('other.latestMarketingInformation')
            }
          </div>
          {
            marketInformationList.map((post, key) => {
              return (
                <div className='post' key={key}>
                  <div className='title btn' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>{post.title[i18n.language]}</div>
                  <div className='date'>
                    {
                      post.displayDateTime &&
                      moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default Layout
