import React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from '@src/utils/commonUtil'
import FooterMenu from '@hkpca-web/components/navigations/FooterMenu'
import { withTranslation } from 'react-i18next'

@inject('initDataStore')
@observer
@withTranslation()

class Layout extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { i18n, initDataStore } = this.props
    const menuList = initDataStore.allContents.informations.menu
    const footerMenuList = initDataStore.allContents.informations.footerMenu.filter((menu) => menu.level === 1)

    return (
      <>
        <div className='footer'>
          <FooterMenu menuList={menuList} footerMenuList={footerMenuList} />
          <hr />
          <div className='container copy-right'>{i18n.t('other.copyRight')}</div>
        </div>
      </>
    )
  }
}

export default Layout
