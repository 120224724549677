import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { navigate } from 'gatsby-link'
import Skeleton from '@material-ui/lab/Skeleton'


@withTranslation()

class News extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClick = (redirectUrl) => {
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    }
  }

  render () {
    const { handleClick } = this
    const { i18n, partnerList } = this.props
    return (
      <>
        <div className='partner-section'>
          <div className='container'>
            {/* <div className='section-title'>{i18n.t('home.ourPartnerTitle')}</div> */}
            <div className='partner-list'>
              {
                partnerList.map((partner, key) => {
                  return (
                    <div className='card btn' key={key} onClick={handleClick.bind(this, partner.redirectUrl)}>
                      <div className='image-container'>
                        <div className='image'>
                          {
                            partner.image && partner.image[0]
                              ?
                              <img src={partner.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                              :
                              <Skeleton
                                animation={false}
                                variant="rect"
                                height={500}
                              />
                          }
                        </div>
                      </div>
                      <div className='company-name'>
                        {partner.companyName[i18n.language]}
                      </div>
                      <div className='description truncate-overflow'>
                        {partner.description[i18n.language]}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className='view-all-button' onClick={navigate.bind(this, '/partner')}>{i18n.t('home.viewAll')}</div>
          </div>
        </div>
      </>
    )
  }
}

export default News
