import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { t, toJS, trimHtmlTag, getBreadcrumb } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
// import PostDetail from '@hkpca-web/components/templates/PostDetail'
import Page from '@hkpca-web/components/templates/Page'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
// import { PDFViewer } from 'react-view-pdf'
// import DocViewer from "react-doc-vier"
// import PDFViewer from 'pdf-viewer-reactjs'
// import 'material-design-icons/iconfont/material-icons.css'
// import { Document, Page } from 'react-pdf'
// import { Document } from 'react-pdf/dist/esm/entry.webpack';
// import { Document } from 'react-pdf/dist/umd/entry.webpack';
// import { Document } from 'react-pdf/dist/esm/entry.parcel';
// import CustomPdfViewer from '@hkpca-web/components/viewers/CustomPdfViewer'

@inject('initDataStore')
@observer

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: null,
    }
  }

  componentDidMount () {
    this.init()
  }

  init = async () => {
    const page = window.location.pathname.replaceAll('/', '')
    this.setState({
      page: page
    })
  }

  render () {
    const { initDataStore } = this.props
    if (!this.state.page) {
      return (<></>)
    }
    // console.log(this.state.page)

    const data = initDataStore.allContents.informations.page.find((page) => page.identifier === this.state.page)
    return (
      <>
        <div className='contact-us'>
          <div className='container flex-row space-between'>
            <div className='left-container'>
              {
                data &&
                <Page data={data} />
              }
            </div>
            <div className='right-container'>
              <LatestPostList
                newsList={initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')}
                eventList={initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')}
                marketInformationList={initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
