import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import PostList from '@hkpca-web/components/templates/PostList'
import { trimHtmlTag, toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import Collapse from '@material-ui/core/Collapse'
import { navigate } from 'gatsby-link'

@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.init()
  }

  init = () => {
    const state = {
      list: null,
      offset: 0,
      limit: 20,
    }
    const pathList = window.location.pathname.split('/')
    switch (pathList[1]) {
      case 'event':
        state.postTypeList = this.props.initDataStore.allContents.categories.eventType
        state.basePath = '/event/detail'
        state.postTypeKey = 'eventType'
        state.postType = 'EVENT'
        break;
      case 'exhibition':
        state.postTypeList = this.props.initDataStore.allContents.categories.exhibitionType
        state.basePath = '/exhibition/detail'
        state.postTypeKey = 'exhibitionType'
        state.postType = 'EXHIBITION'
        break;
      case 'market-information':
        state.postTypeList = this.props.initDataStore.allContents.categories.marketInformationType
        state.basePath = '/market-information/detail'
        state.postTypeKey = 'marketInformationType'
        state.postType = 'MARKET_INFORMATION'
        break;
      default:
        state.postType = 'ALL'
        break;
    }
    switch (pathList[2]) {
      case 'seminar-training':
        state.category = 'SEMINAR_TRAINING'
        break;
      case 'other-activities':
        state.category = 'OTHER_ACTIVITIES'
        break;
      case 'exhibition':
        state.category = 'EXHIBITION'
        break;
      case 'market-news':
        state.category = 'MARKET_NEWS'
        break;
      case 'ep-policies':
        state.category = 'EP_POLICIES'
        break;
      case 'marketing-research':
        state.category = 'MARKETING_RESEARCH'
        break;
      case 'technical-article':
        state.category = 'TECHNICAL_ARTICLE'
        break;
      default:
        state.category = 'ALL'
        break;
    }
    return state
  }

  componentDidMount () {
    const { initDataStore } = this.props
    const { postType, postTypeKey } = this.state
    const allPosts = initDataStore.allContents.informations.post.filter((post) => post.type === postType)
    let list = allPosts
    if (this.state.category !== 'ALL') {
      list = allPosts.filter((post) => post[postTypeKey] === this.state.category)
    }
    list = list.slice(0, 5)
    this.setState({
      list: list
    })
  }

  handleChange = (value) => {
    const { basePath, postTypeList } = this.state
    const postType = postTypeList.find((type) => type.value === value)
    if (!postType) {
      navigate(`/${basePath}`)
    } else {
      navigate(`/${basePath}${postType.path}`)
    }
  }

  loadContent = async () => {
    const { initDataStore } = this.props
    const { postType, category, offset, limit } = this.state
    const data = await initDataStore.searchPostList('', postType, category, offset, limit)
    this.setState({
      offset: offset + limit,
      list: [...this.state.list, ...data]
    })
    return data
  }

  render () {
    const { initDataStore } = this.props
    const { tooglePostDetail, handleChange, loadContent } = this
    const { basePath, postTypeKey, postTypeList, postType, category, collapseStateList, list } = this.state
    if (!list) {
      return (<></>)
    }
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKETING_RESEARCH')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='post-list-container'>
          <div className='responsive-container'>
            <div className='left-container'>
              <div className='filter flex-row align-items-center'>
                <div className='title'>Categories</div>
                <div className='category-picker'><CategoryPicker optionList={postTypeList} value={this.state.category} onSubmit={handleChange} hasNoneOption={false} hasAllOption={true} /></div>
              </div>
              <PostList path={basePath} list={list} loadContent={loadContent} />
            </div>
            <div className='right-container'>
              <LatestPostList newsList={newsList.slice(0, 3)} eventList={eventList.slice(0, 3)} marketInformationList={marketInformationList.slice(0, 3)} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
