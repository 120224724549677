import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import wechatIcon from '@hkpca-web/images/qrcode.png'

class Logo extends React.Component {
  constructor (props) {
    super(props)
  }
  render () {
    // const logo = '../../images/build-in/logo-white.png'
    return (
      <>
        <img src={wechatIcon} />
        {/* <StaticImage
            placeholder="blurred"
            layout="fixed"
            src={logo}
            alt="Logo"
            height={54}
          /> */}
      </>
    )
  }
}

export default Logo
