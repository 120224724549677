import React from 'react'
import { inject, observer } from 'mobx-react'
import CustomTextField from '@hkpca-web/components/inputs/CustomTextField'
import CustomTextArea from '@hkpca-web/components/inputs/CustomTextArea'
import CustomPicker from '@hkpca-web/components/inputs/CustomPicker'
import CustomDatePicker from '@hkpca-web/components/inputs/CustomDatePicker'
import { Formik, Form, Field } from 'formik'
import {
  Button,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as yup from 'yup'
import { toJS, trimHtmlTag } from '@src/utils/commonUtil'

@withTranslation()
@inject('initDataStore')
@observer

class MemberForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      showError: false
    }
  }

  toogleDialogOpen = () => {
    this.setState({ open: !this.state.open })
  };

  handleValidateForm = () => {
    this.setState({ showError: true })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors, resetForm }) => {
    const { initDataStore } = this.props
    await initDataStore.submitMemberForm(values)
    this.toogleDialogOpen()
  }

  refreshPage = async () => {
    window.location.reload()
  }

  render () {
    const { handleValidateForm, handleSubmitForm, toogleDialogOpen, refreshPage } = this
    const { i18n, initDataStore } = this.props
    const { open, showError } = this.state
    const memberBusinessNature = initDataStore.allContents.categories.memberBusinessNature
    const memberCity = initDataStore.allContents.categories.memberCity
    const memberLocation = initDataStore.allContents.categories.memberLocation
    const membershipType = initDataStore.allContents.categories.membershipType
    const memberFormPage = initDataStore.allContents.informations.page.find((page) => page.identifier === 'memberForm')
    const memberInvestmentCurrency = initDataStore.allContents.categories.memberInvestmentCurrency

    const schemaConfig = {
      membershipType: yup.string().required(),
      companyNameInEnglish: yup.string().required(),
      companyNameInChinese: yup.string().required(),
      addressLine1InEnglish: yup.string().required(),
      addressLine2InEnglish: yup.string().required(),
      cityInEnglish: yup.string().required(),
      countryInEnglish: yup.string().required(),
      countryInChinese: yup.string().required(),
      cityInChinese: yup.string().required(),
      addressLine1InChinese: yup.string().required(),
      addressLine2InChinese: yup.string().required(),
      phoneNumber: yup.string().required(),
      faxNumber: yup.string().required(),
      website: yup.string().required(),
      representativeInEnglish: yup.string().required(),
      representativeInChinese: yup.string().required(),
      representativeTitleInEnglish: yup.string().required(),
      representativeTitleInChinese: yup.string().required(),
      contactPersonInEnglish: yup.string().required(),
      contactPersonInChinese: yup.string().required(),
      email1: yup.string().required(),
      email2: yup.string().required(),
      email3: yup.string().required(),
      businessNature: yup.string().required(),
      companyProfile: yup.string().required(),
      purposeofJoiningHKPCA: yup.string().required(),
      memberInvestmentCurrency: yup.string().required(),
      investmentAmount: yup.string().required(),
      numberOfEmployeeInHK: yup.string().required(),
      numberOfEmployeeInCN: yup.string().required(),
      numberOfEmployeeInOther: yup.string().required(),
      managerialLevelInHK: yup.string().required(),
      managerialLevelInCN: yup.string().required(),
      managerialLevelInOther: yup.string().required(),
      technicianInHK: yup.string().required(),
      technicianInCN: yup.string().required(),
      technicianInOther: yup.string().required(),
      workmanInHK: yup.string().required(),
      workmanInCN: yup.string().required(),
      workmanInOther: yup.string().required(),
      journalNameInEnglish: yup.string().required(),
      journalNameInChinese: yup.string().required(),
      telephoneNumber: yup.string().required(),
      journalEmail: yup.string().required(),
      postalJournalReceiver: yup.string().required(),
      postalTelephone: yup.string().required(),
      postalMailingAddress: yup.string().required()
    }
    const validationSchema = yup.object().shape(schemaConfig)

    return (
      <>
        <div className='member-form'>
          <div className='container'>
            <Formik
              initialValues={initDataStore.memberForm}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >
              {(formikProps) => {
                console.log(formikProps.errors)
                return (
                  <Form>
                    <div className='section'>
                      <div className='content'>
                        {
                          trimHtmlTag(memberFormPage.content[i18n.language]) !== '' &&
                          <div className='rich-editor'>
                            <div dangerouslySetInnerHTML={{ __html: memberFormPage.content[i18n.language] }} />
                          </div>
                        }
                        {
                          memberFormPage.document.length > 0 &&
                          <div className='download flex-row'>
                            <div><a href={`${memberFormPage.document[0].original.replace('http://', 'https://')}`} target="_blank">{i18n.t('post.download')}</a></div>
                          </div>
                        }
                      </div>
                      <div className='section-title'>{i18n.t('member.section1Title')}</div>
                      {/* <div className='section-description'>{i18n.t('member.section1Description')}</div> */}
                      {/* <div className='section-download'><a href={downloadUrl.file[0].original.replace('http://', 'https://')}>download</a></div> */}
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.typeOfMembership')}</div>
                        <div className='field w260'>
                          <Field
                            name={'membershipType'}
                            errors={showError ? formikProps.errors : false}
                            component={CustomPicker}
                            optionList={membershipType}
                            language={i18n.language}
                          // placeholder={i18n.t('member.city')}
                          />
                        </div>
                      </div>
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.companyNameLabel')}</div>
                        <div className='flex-row'>
                          <div className='field w500'>
                            <Field
                              name={'companyNameInEnglish'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.english')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w500'>
                            <Field
                              name={'companyNameInChinese'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.chinese')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='address flex-row'>
                        <div className='flex-column'>
                          <div>
                            <div className='label'>{i18n.t('member.companyAddressLabel')}</div>
                            <div className='flex-row'>
                              <div className='field w500'>
                                <Field
                                  name={'addressLine1InEnglish'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomTextField}
                                  placeholder={i18n.t('member.addressLine1InEnglishPlaceholder')}
                                />
                                <Field
                                  name={'addressLine2InEnglish'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomTextField}
                                  placeholder={i18n.t('member.addressLine2InEnglishPlaceholder')}
                                />
                                <Field
                                  name={'cityInEnglish'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomPicker}
                                  optionList={memberCity}
                                  language='en'
                                // placeholder={i18n.t('member.city')}
                                />
                                <Field
                                  name={'countryInEnglish'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomPicker}
                                  optionList={memberLocation}
                                  language='en'
                                // placeholder={i18n.t('member.addressLine2InChinesePlaceholder')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div>
                            <div className='label'>{i18n.t('member.companyAddressLabel')}</div>
                            <div className='flex-row'>
                              <div className='field w500'>
                                <Field
                                  name={'countryInChinese'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomPicker}
                                  optionList={memberLocation}
                                  language={i18n.language === 'sc' ? 'sc' : 'tc'}
                                // placeholder={i18n.t('member.companyNameEnglishPlaceholder')}
                                />
                                <Field
                                  name={'cityInChinese'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomPicker}
                                  optionList={memberCity}
                                  language={i18n.language === 'sc' ? 'sc' : 'tc'}
                                // placeholder={i18n.t('member.companyNameEnglishPlaceholder')}
                                />
                                <Field
                                  name={'addressLine1InChinese'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomTextField}
                                  placeholder={i18n.t('member.addressLine1InChinese')}
                                />
                                <Field
                                  name={'addressLine2InChinese'}
                                  errors={showError ? formikProps.errors : false}
                                  component={CustomTextField}
                                  placeholder={i18n.t('member.addressLine2InChinese')}
                                />
                              </div>
                              {/* <div className='space' /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.phoneNumberLabel')}</div>
                          <div className='field w260'>
                            <Field
                              name={'phoneNumber'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameEnglishPlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.faxNumberLabel')}</div>
                          <div className='field w260'>
                            <Field
                              name={'faxNumber'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.websiteLabel')}</div>
                          <div className='field w260'>
                            <Field
                              name={'website'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.representative')}</div>
                        <div className='flex-row'>
                          <div className='field w500'>
                            <Field
                              name={'representativeInEnglish'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.english')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w500'>
                            <Field
                              name={'representativeInChinese'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.chinese')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.representativeTitle')}</div>
                        <div className='flex-row'>
                          <div className='field w500'>
                            <Field
                              name={'representativeTitleInEnglish'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.english')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w500'>
                            <Field
                              name={'representativeTitleInChinese'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.chinese')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.contactPerson')}</div>
                        <div className='flex-row'>
                          <div className='field w500'>
                            <Field
                              name={'contactPersonInEnglish'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.english')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w500'>
                            <Field
                              name={'contactPersonInChinese'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.chinese')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.emailLabel')}</div>
                        <div className='flex-row'>
                          <div className='field w260'>
                            <Field
                              name={'email1'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w260'>
                            <Field
                              name={'email2'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w260'>
                            <Field
                              name={'email3'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.businessNature')}</div>
                          <div className='field w260'>
                            <Field
                              name={'businessNature'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomPicker}
                              optionList={memberBusinessNature}
                              language={i18n.language}
                            // placeholder={i18n.t('member.city')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='section'>
                      <div className='section-title'>{i18n.t('member.companyInformation')}</div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.companyProfile')}</div>
                          <div className='flex-row'>
                            <div className='field w500'>
                              <Field
                                name={'companyProfile'}
                                errors={showError ? formikProps.errors : false}
                                component={CustomTextArea}
                              // placeholder={i18n.t('member.representativeInEnglishPlaceholder')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.purposeofJoiningHKPCA')}</div>
                          <div className='flex-row'>
                            <div className='field w500'>
                              <Field
                                name={'purposeofJoiningHKPCA'}
                                errors={showError ? formikProps.errors : false}
                                component={CustomTextArea}
                              // placeholder={i18n.t('member.representativeInEnglishPlaceholder')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.investmentAmount')}</div>
                          <div className='field w260'>
                            <Field
                              name={'memberInvestmentCurrency'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomPicker}
                              optionList={memberInvestmentCurrency}
                              language={i18n.language}
                            // placeholder={i18n.t('member.city')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'investmentAmount'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.hkd')}
                            />
                          </div>
                        </div>

                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.numberOfEmployee')}</div>
                          <div className='field w260'>
                            <Field
                              name={'numberOfEmployeeInHK'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.hongKong')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'numberOfEmployeeInCN'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.china')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'numberOfEmployeeInOther'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.other')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.managerialLevel')}</div>
                          <div className='field w260'>
                            <Field
                              name={'managerialLevelInHK'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.hongKong')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'managerialLevelInCN'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.china')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'managerialLevelInOther'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.other')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.technician')}</div>
                          <div className='field w260'>
                            <Field
                              name={'technicianInHK'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.hongKong')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'technicianInCN'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.china')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'technicianInOther'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.other')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.workman')}</div>
                          <div className='field w260'>
                            <Field
                              name={'workmanInHK'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.hongKong')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'workmanInCN'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.china')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label hide-in-mobile'></div>
                          <div className='field w260'>
                            <Field
                              name={'workmanInOther'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.other')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='section'>
                      <div className='section-title'>{i18n.t('member.companyProfileInHKPCAJournal')}</div>
                      <div className='flex-column'>
                        <div className='label'>{i18n.t('member.name')}</div>
                        <div className='flex-row'>
                          <div className='field w500'>
                            <Field
                              name={'journalNameInEnglish'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.english')}
                            />
                          </div>
                          <div className='space' />
                          <div className='field w500'>
                            <Field
                              name={'journalNameInChinese'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              placeholder={i18n.t('member.chinese')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.telephoneNumber')}</div>
                          <div className='field w500'>
                            <Field
                              name={'telephoneNumber'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameEnglishPlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.emailAddress')}</div>
                          <div className='field w500'>
                            <Field
                              name={'journalEmail'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='section'>
                      <div className='section-title'>{i18n.t('member.postalInformationForJournalAndOtherCorrespondence')}</div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.journalReceiver')}</div>
                          <div className='field w500'>
                            <Field
                              name={'postalJournalReceiver'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.hkd')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.telephoneNumber')}</div>
                          <div className='field w500'>
                            <Field
                              name={'postalTelephone'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.telephoneNumber')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.mailingAddress')}</div>
                          <div className='field w500'>
                            <Field
                              name={'postalMailingAddress'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextArea}
                            // placeholder={i18n.t('member.rmb')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                      </div>
                    </div>
                    <hr />
                    {
                      showError && Object.keys(formikProps.errors).length > 0 && (
                        <div className='error-message'>{i18n.t('member.inputErrorMessage')}</div>
                      )
                    }
                    <div className='submit-button-container'>
                      <Button type='submit' className='submit-button' disableElevation variant='contained' color='primary' onClick={handleValidateForm}>{i18n.t('member.submitButtonText')}</Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <Dialog
            open={open}
            onClose={toogleDialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle id="alert-dialog-title">
              {i18n.t('other.dialogTitle')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {i18n.t('other.dialogSuccessMessage')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={refreshPage} color="primary">
                {i18n.t('other.dialogButtonText')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }
}
export default MemberForm
