import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS, trimHtmlTag, truncateString } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { initDataStore, i18n } = this.props
    const jobOpportunitiesList = initDataStore.allContents.informations.jobOpportunities
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        {/* <Breadcrumb title={this.state.title} /> */}
        {/* <div className='filter'>
          <div className='title'>Categories</div>
        </div> */}
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='job-opportunities-list'>
              {
                jobOpportunitiesList.map((post, key) => {
                  toJS(post)
                  return (
                    <div key={key}>
                      <div className='card post' onClick={navigate.bind(this, `/job-opportunity-detail?id=${post.id}`)}>
                        <div className='flex-column'>
                          {/* <img className='image' src={post.image[0].original.replace('http://', 'https://')} alt="" /> */}
                          <div className='date'>
                            {
                              post.displayDateTime &&
                              moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                            }
                          </div>
                          <div className='title'>
                            {post.title[i18n.language]}
                          </div>
                          <div className='description'>
                            {truncateString(trimHtmlTag(post.content[i18n.language]), 200)}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
          </div>
        </div>
      </>
    )
  }
}

export default Test
