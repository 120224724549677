import apiClient from '@src/services/apiClient'
import { searchPostListPath } from '@hkpca-web/config/apiPaths'

export const searchPostList = (searchText, postType, category, offset, limit) => {
  return apiClient.post(
    searchPostListPath,
    {
      searchText,
      postType,
      category,
      offset,
      limit
    }
  )
}
