import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { t, toJS, trimHtmlTag, getBreadcrumb } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
import Page from '@hkpca-web/components/templates/Page'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'

@inject('initDataStore')
@observer

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: null,
    }
  }

  componentDidMount () {
    this.init()
  }

  init = async () => {
    const list = window.location.pathname.split('/')
    let page = list[list.length - 1]
    if (!page) {
      page = list[list.length - 2]
    }
    this.setState({
      page: page
    })
  }

  render () {
    const { initDataStore } = this.props
    if (!this.state.page) {
      return (<></>)
    }
    const data = initDataStore.allContents.informations.page.find((page) => page.identifier === this.state.page)
    return (
      <>
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='about-us'>
              <Page data={data} />
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList
              newsList={initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')}
              eventList={initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')}
              marketInformationList={initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')}
            />
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
