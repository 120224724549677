import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import moment from 'moment'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {
  BrowserView,
  MobileOnlyView,
  TabletView,
  isBrowser,
  isMobile,
  isTablet
} from "react-device-detect"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import envConfig from '@src/configs/envConfig'
import { withTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import Skeleton from '@material-ui/lab/Skeleton'
import { getPostDetailPath } from '@src/utils/hkpcaUtil'

@withTranslation()

class News extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClick = (postId, postType, redirectUrl) => {
    const postDetailPath = getPostDetailPath(postType)
    const url = `${postDetailPath}?id=${postId}`
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    } else {
      navigate(url)
    }
  }

  render () {
    const { handleClick } = this
    // const logo = '../../images/build-in/logo-white.png'
    const { i18n, eventList } = this.props
    const reminder = 7 - eventList.length
    // toJS(isTablet)
    const settings = {
      dots: true,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 500,
      arrows: false,
      adaptiveHeight: true,
    }
    return (
      <>
        <div className='event-section'>
          <BrowserView>
            <div className='container'>
              <div className='flex-row wrap'>
                <div className='card btn'>
                  <div className='overlay'>
                    <div className='view-all-title'>{i18n.t('home.ourEventsTitle')}</div>
                    <div className='view-all-description'>{i18n.t('home.ourEventsDescription')}</div>
                    <div className='view-all-button' onClick={navigate.bind(this, `/event`)}>{i18n.t('home.viewAll')}</div>
                  </div>
                </div>
                {
                  eventList.map((post, key) => {
                    return (
                      <div key={key}>
                        <div className='card' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>
                          <div className='image-container'>
                            <div className='image'>
                              {
                                post.image && post.image[0]
                                  ?
                                  <img src={post.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                                  :
                                  <Skeleton
                                    animation={false}
                                    variant="rect"
                                    height={500}
                                  />
                              }
                            </div>
                          </div>
                          <div className='title-container flex-row space-between align-items-center'>
                            <div className='title truncate-overflow'>{post.title[i18n.language]}</div>
                            <div className='icon'><ChevronRightIcon /></div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </BrowserView>
          <MobileOnlyView>
            <div className='slider-container'>
              <Slider {...settings}>
                <div>
                  <div className='first-card'>
                    <div className='overlay flex-column justify-content-center align-items-center'>
                      <div className='view-all-title'>Our Events</div>
                      <div className='view-all-description'>HKPCA Event Apercu International Event Apercu</div>
                      <div className='view-all-button' onClick={navigate.bind(this, `/event`)}>View all</div>
                    </div>
                  </div>
                </div>
                {
                  eventList.map((post, key) => {
                    return (
                      <div key={key}>
                        <div className='card btn' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>
                          <div className='image-container'>
                            {
                              post.image && post.image[0]
                                ?
                                <img src={post.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                                :
                                <Skeleton
                                  animation={false}
                                  variant="rect"
                                  height={500}
                                />
                            }
                          </div>
                          <div className='title-container flex-row space-between align-items-center'>
                            <div className='title'>{post.title[i18n.language]}</div>
                            <div className='icon'><ChevronRightIcon /></div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </MobileOnlyView>
          {/* <TabletView>
            <div className='container'>
              <div className='flex-row wrap'>
                <div className='card'>
                  <div className='overlay'>
                    <div className='view-all-title'>Our Events</div>
                    <div className='view-all-description'>HKPCA Event Apercu International Event Apercu</div>
                    <div className='view-all-button' onClick={navigate.bind(this, `/event`)}>View all</div>
                  </div>
                </div>
                {
                  eventList.map((event, key) => {
                    return (
                      <div key={key}>
                        <div className='card'>
                          <div className='image-container'>
                            {
                              post.image && post.image[0]
                                ?
                                <img src={post.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                                :
                                <Skeleton
                                  animation={false}
                                  variant="rect"
                                  height={500}
                                />
                            }
                          </div>
                          <div className='title-container flex-row space-between align-items-center'>
                            <div className='title truncate-overflow'>{post.title[i18n.language]}</div>
                            <div className='icon'><ChevronRightIcon /></div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </TabletView> */}
        </div>
      </>
    )
  }
}

export default News
