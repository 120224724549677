import React from 'react'
import { navigate } from "gatsby"
import { toJS } from '@src/utils/commonUtil'
import { withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

@inject('initDataStore')
@withTranslation()

class Menu extends React.Component {
  constructor (props) {
    super(props)
  }
  render () {
    const { i18n, menuList, initDataStore } = this.props
    const menu = menuList.find((menu) => menu.level === 1)
    menu.level2 = menuList.filter((menu) => menu.level === 2)
    menu.level2.forEach((level2item, key) => {
      menu.level2[key]['level3'] = menuList.filter((item) => level2item.identifier === item.parentIdentifier)
    })

    const pageList = initDataStore.allContents.informations.page.filter((page) => page.customPostType !== 'SYSTEM')

    return (
      <>
        <div className='menu'>
          {
            menu.level2.map((level2item, key) => {
              // if (menu.path === '/') {
              //   return <div className='active' onClick={navigate.bind(this, `/ef${menu.path}`)} key={key}>{menu.title[i18n.language]}</div>
              // }
              // if (location.pathname.includes(menu.path)) {
              //   return <div className='active' onClick={navigate.bind(this, `${menu.path}`)} key={key}>{menu.title[i18n.language]}</div>
              // }
              return (
                <div className='dropdown' key={key}>
                  <div className='dropdown-title' onClick={navigate.bind(this, `${level2item.path}`)}>{level2item.title[i18n.language]}</div>
                  <div className="dropdown-content">
                    <div className='transparent' />
                    {
                      level2item.identifier === 'event' &&
                      pageList.map((page, key2) => {
                        // console.log("HaSan:", key2);
                        return (
                          <div key={key2} className='item' onClick={navigate.bind(this, `/custom-page/?identifier=${page.identifier}`)}>{page.title[i18n.language]}</div>
                        )
                      })
                    }

                    {
                      level2item.level3.map((level3item, key2) => {
                        return (
                          <div key={key2} className='item' onClick={navigate.bind(this, `${level3item.path}`)}>{level3item.title[i18n.language]}</div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
}

export default Menu
