import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import PostList from '@hkpca-web/components/templates/PostList'
import { trimHtmlTag, toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import Collapse from '@material-ui/core/Collapse'
import { navigate } from 'gatsby-link'
import { withTranslation } from 'react-i18next'
// import Pagination from 'material-ui-flat-pagination'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      type: null,
      isReady: false
    }
  }

  componentDidMount () {
    const { initDataStore } = this.props
    // const { postType, postTypeKey } = this.state
    const parsed = queryString.parse(window.location.search)
    const customPostType = parsed.type
    const allPosts = initDataStore.allContents.informations.post.filter((post) => post.customPostType === customPostType)
    toJS(allPosts)
    let list = allPosts
    const total = list.length
    this.setState({
      list: list,
      total: total,
      type: customPostType,
      isReady: true,
      detailPath: '/custom-post'
    })

    // this.setState({
    //   id: parseInt(parsed.id),
    //   isReady: true
    // })
  }

  componentDidUpdate () {
    // const parsed = queryString.parse(location.search)
    // const postId = parseInt(parsed.id)
    // if (this.state.id !== postId) {
    //   this.setState({
    //     id: postId,
    //     isReady: false
    //   }, () => {
    //     this.setState({
    //       isReady: true
    //     })
    //     // const { structureStore } = this.props
    //     // structureStore.fetchStructureDetail(structureId)
    //   })
    // }
  }

  render () {
    const { initDataStore } = this.props
    if (!this.state.type) {
      return (<></>)
    }
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKETING_RESEARCH')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    const { detailPath, list } = this.state
    return (
      <>
        <div className='post-list-container'>
          <div className='responsive-container'>
            <div className='left-container'>
              <PostList path={detailPath} list={list} />
              {/* <div className='pagination'>
                <Pagination
                  limit={limit}
                  offset={offset}
                  total={total}
                  // onClick={offsetAction}
                  currentPageColor='primary'
                // otherPageColor='primary'
                />
              </div> */}
            </div>
            <div className='right-container'>
              <LatestPostList
                newsList={newsList.slice(0, 3)}
                eventList={eventList.slice(0, 3)}
                marketInformationList={marketInformationList.slice(0, 3)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
