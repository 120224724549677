import React from 'react'
import { inject, observer } from 'mobx-react'
import Logo from '@hkpca-web/components/common/logo'
// import Menu from '@hkpca-web/components/home/menu'
import { toJS } from '@src/utils/commonUtil'
import { navigate } from "gatsby"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CustomMenu from '@hkpca-web/components/common/CustomMenu'
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.languages = {
      en: 'EN',
      tc: '繁',
      sc: '简',
    }
    this.state = {
      isDrawerOpened: false
    }
  }
  toggleDrawer = () => {
    this.setState({ isDrawerOpened: !this.state.isDrawerOpened })
  }
  handleChangeLanguage = async (langCode, langText) => {
    const { initDataStore } = this.props
    await initDataStore.changeLanguage(langCode)
    this.props.i18n.changeLanguage(langCode)
    this.setState({
      langText: langText,
      languageAnchorEl: null,
      isDrawerOpened: false
    })
  }

  render () {
    const { handleChangeLanguage, languages } = this
    const { i18n, initDataStore } = this.props
    const menuList = initDataStore.allContents.informations.menu
    return (
      <>
        <div className='header'>
          <AppBar className='app-bar' position="relative" elevation={0} style={{ backgroundColor: '#0B1C53' }}>
            <Toolbar>
              <div className='flex-row space-between align-items-center full-width'>
                <MenuIcon className='menu-icon' onClick={this.toggleDrawer} color="inherit" />
                <div className='logo' onClick={navigate.bind(this, `/`)}><Logo /></div>
                <div className='box' />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer open={this.state.isDrawerOpened} onClose={this.toggleDrawer}>
            <div className='app-bar-menu'>
              <div className='top-bar flex-row space-between'>
                <div className='logo' onClick={navigate.bind(this, `/`)}><Logo /></div>
                <div onClick={this.toggleDrawer} style={{ color: '#fff', padding: '10px' }}><CloseIcon /></div>
              </div>
              <div className='languages'>
                {
                  Object.keys(languages).map((code, key) => {
                    return (
                      i18n.language !== code
                        ? <div key={key} onClick={handleChangeLanguage.bind(this, code)} >{languages[code]}</div>
                        : <div className='active' key={key} onClick={handleChangeLanguage.bind(this, code)} >{languages[code]}</div>
                    )
                  })
                }
              </div>
              <CustomMenu menuList={menuList} />
            </div>
          </Drawer>
        </div>
      </>
    )
  }
}

export default Test
