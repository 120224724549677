export const getPostDetailPath = (type) => {
  let path
  // console.log(type)
  switch (type) {
    case 'NEWS':
      path = '/news/detail'
      break;
    case 'EVENT':
      path = '/event/detail'
      break;
    case 'EXHIBITION':
      path = '/exhibition/detail'
      break;
    case 'MARKET_INFORMATION':
      path = '/market-information/detail'
      break;
    default:
      path = '/post/detail'
      break;
  }
  // console.log(path)
  return path
}
