import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS, trimHtmlTag } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

@inject('initDataStore')
@observer
@withTranslation()

class Test extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClick = (postId, redirectUrl) => {
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    } else {
      navigate(`/member/member-news/detail?id=${postId}`)
    }
  }

  render () {
    const { i18n, list } = this.props
    const { handleClick } = this
    
    return (
      <>
        <div className='post-list'>
          {
            list.map((post, key) => {
              return (
                <div key={key}>
                  <div className='news card' onClick={handleClick.bind(this, post.id, post.redirectUrl)}>
                    <div className='flex-column'>
                      <div className='date'>
                        {
                          post.displayDateTime &&
                          moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                        }
                      </div>
                      <div className='title'>
                        {post.title[i18n.language]}
                      </div>
                      <div className='description'>
                        {trimHtmlTag(post.content[i18n.language])}
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
}

export default Test
