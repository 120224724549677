import React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from '@src/utils/commonUtil'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { navigate, Link } from 'gatsby'

@inject('initDataStore')
@observer
@withTranslation()

class CustomBreadcrumb extends React.Component {
  constructor (props) {
    super(props)
    const menuList = props.initDataStore.allContents.informations.menu
    const pathList = window.location.pathname.split('/')
    const footerMenuList = props.initDataStore.allContents.informations.footerMenu
    const level1 = menuList.find((menu) => (menu.level === 1))
    let level2 = pathList[1] !== '' ? menuList.find((menu) => (menu.level === 2 && menu.path.includes(pathList[1]))) : null
    let level3 = pathList[2] !== '' ? menuList.find((menu) => (menu.level === 3 && menu.path.includes(pathList[2]))) : null
    let fTitle = (pathList[2] === '' || pathList[2] === undefined) ? footerMenuList.find((menu) => (menu.path.includes(pathList[1]))) : null
    // const parsed = queryString.parse(location.search)
    // let post = null
    // if (pathList[2] === 'detail' && parsed.id !== undefined) {
    //   post = props.initDataStore.allContents.informations.post.find((post) => post.id === parseInt(parsed.id))
    // }
    // toJS(pathList)
    // toJS(footerMenuList.find((menu) => (menu.path.includes(pathList[1]))))

    if (pathList[1] === 'news') {
      const parsed = queryString.parse(window.location.search)
      const searchText = parsed.text
      fTitle = {
        title: {
          en: `Latest News`,
          tc: `最新消息`,
          sc: `最新消息`,
        }
      }
      level2 = null
      // toJS(fTitle)
    }
    if (pathList[1] === 'partner') {
      const parsed = queryString.parse(window.location.search)
      const searchText = parsed.text
      fTitle = {
        title: {
          en: ``,
          tc: ``,
          sc: ``,
        }
      }
      level2 = null
      // toJS(fTitle)
    }
    if (pathList[1] === 'custom-page') {
      const parsed = queryString.parse(window.location.search)
      const identifier = parsed.identifier
      const page = props.initDataStore.allContents.informations.page.find((page) => page.identifier === identifier)
      fTitle = {
        title: page.title
      }
      level2 = null
      // toJS(fTitle)
    }
    if (pathList[1] === 'custom-list') {
      const parsed = queryString.parse(window.location.search)
      const type = parsed.type
      const customPostType = props.initDataStore.allContents.categories.customPostType.find((customPostType) => customPostType.value === type)
      fTitle = {
        title: customPostType.label
      }
      level2 = null
      // toJS(fTitle)
    }
    if (pathList[1] === 'custom-post') {
      const parsed = queryString.parse(window.location.search)
      const id = parsed.id
      const post = props.initDataStore.allContents.informations.post.find((post) => post.id === parseInt(id))
      // toJS(post)
      fTitle = {
        title: post.title
      }
      level2 = null
      // toJS(fTitle)
    }
    if (pathList[1] === 'search-result') {
      const parsed = queryString.parse(window.location.search)
      const searchText = parsed.text
      fTitle = {
        title: {
          en: `Search Result: ${searchText}`,
          tc: `搜索結果: ${searchText}`,
          sc: `搜索结果: ${searchText}`,
        }
      }
      level2 = null
      // toJS(fTitle)
    }
    if (pathList[2] === 'detail') {
      const parsed = queryString.parse(window.location.search)
      const id = parsed.id
      const post = props.initDataStore.allContents.informations.post.find((post) => post.id === parseInt(id))
      switch (post.type) {
        case 'EVENT':
          break;
        case 'EXHIBITION':
          // const subType = exhibitionTag[0]
          break;
        case 'MARKET_INFORMATION':
          const marketInformationType = props.initDataStore.allContents.categories.marketInformationType.find((type) => type.value === post.marketInformationTag[0])
          level3 = {
            title: marketInformationType.label
          }
          break;
        default:
          break;
      }

    }

    this.state = {
      level1: level1,
      level2: level2,
      level3: level3,
      fTitle: fTitle,
      // post: post
    }
  }

  componentDidMount () {
    // const { initDataStore } = this.props
    // toJS(pathList)
    // toJS(initDataStore.allContents.informations.menu)
  }

  render () {
    const { i18n, title } = this.props
    const { level1, level2, level3, fTitle } = this.state
    // console.log(fTitle)
    // console.log(level1.title[i18n.language])
    // console.log(level2.title[i18n.language])
    // console.log(level3)


    return (
      <>
        <div className='breadcrumb'>
          <div className='container'>
            {
              level2 &&
              <>
                <div className='path flex-row'>
                  <Link to={level1.path}><div>{level1.title[i18n.language]}</div></Link>
                  <div>/</div>
                  <Link to={level2.path}><div>{level2.title[i18n.language]}</div></Link>
                </div>
                {
                  level3
                    ?
                    <div className='title'>{level3.title[i18n.language]}</div>
                    :
                    <div className='title'> {level2.title[i18n.language]}</div>
                }
              </>
            }
            {
              title && (
                <div className='title'>{title}</div>
              )
            }
            {
              fTitle &&
              <>
                {
                  level2 &&
                  <div className='path flex-row'>
                    <Link to={level1.path}><div>{level1.title[i18n.language]}</div></Link>
                    <div>/</div>
                    <Link to={level2.path}><div>{level2.title[i18n.language]}</div></Link>
                  </div>
                }
                <>
                  <Link to={level1.path}><div>{level1.title[i18n.language]}</div></Link>
                  <div className='title'>{fTitle.title[i18n.language]}</div>
                </>
              </>
            }
          </div>
        </div>
      </>
    )
  }
}

export default CustomBreadcrumb
