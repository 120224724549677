import React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from '@src/utils/commonUtil'

class Layout extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <>
        <div className='loading'>
          <div className="spinner">
            <div className="loader">Loading...</div>
          </div>
        </div>
      </>
    )
  }
}

export default Layout
