import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import {
  FormControl,
} from '@material-ui/core'

const styles = {
  input: {
    fontSize: '14px',
    padding: '10px 12px',
    backgroundColor: '#f2f2f2',
    border: '1px solid #f2f2f2',
    transition: 'background-color 0.5s ease',
    '&::placeholder': {
      fontSize: '14px',
    },
  },
  error: {
    fontSize: '14px',
    padding: '10px 12px',
    backgroundColor: '#f2f2f2',
    border: '1px solid red',
    transition: 'background-color 0.5s ease',
    '&::placeholder': {
      fontSize: '14px',
    }
  }
}

@withStyles(styles)

class CustomTextField extends React.Component {

  constructor (props) {
    super(props)
    let defaultValue = ''
    if (this.props.value) {
      defaultValue = this.props.value
    }
    if (this.props.field.value) {
      defaultValue = this.props.field.value
    }
    this.state = {
      value: defaultValue
    }
  }

  handleChange = (event) => {
    const name = this.props.field.name
    let value = event.target.value
    if (this.props.type === 'number') {
      value = parseInt(value)
      if (value > 0) {
        const setFieldValue = this.props.form.setFieldValue
        setFieldValue(name, value)
        this.setState({ value: value })
      }
    } else {
      const setFieldValue = this.props.form.setFieldValue
      setFieldValue(name, value)
      this.setState({ value: value })
    }
  }

  render () {
    const { handleChange } = this
    const { errors, type, placeholder, classes, isFormik = true, onChange } = this.props
    const name = this.props.field.name
    const { value } = this.state

    return (
      <FormControl variant='outlined' fullWidth>
        <InputBase
          type={type}
          variant='outlined'
          value={value}
          fullWidth
          placeholder={placeholder}
          onChange={isFormik ? handleChange : onChange}
          className={(errors && errors[name]) ? classes.error : classes.input}
        />
      </FormControl>
    )
  }
}

export default CustomTextField
