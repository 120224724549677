import React from 'react'
import { inject, observer } from 'mobx-react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'

@inject('initDataStore')
@observer
@withTranslation()

class News extends React.Component {
  constructor (props) {
    super(props)
  }
  render () {
    const { i18n, initDataStore } = this.props
    const linkExchangeList = initDataStore.allContents.informations.linkExchange
    const linkExchangeTypes = initDataStore.allContents.categories.linkExchangeType
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='container flex-row space-between'>
          {/* <div className='left-container'> */}
          <div className='link-exchange'>
            <div className='link-exchange-list'>
              {
                linkExchangeTypes.map((linkExchangeType, key2) => {
                  const list = linkExchangeList.filter((linkExchange) => linkExchange.linkExchangeType === linkExchangeType.value).map((info, key) => {
                    return (
                      <div key={key} className='card btn'>
                        <a href={info.redirectUrl} target="_blank">
                          <img className='image' src={info.image[0].original.replace('http://', 'https://')} alt="" />
                          <div className='company-name'>
                            {info.name[i18n.language]}
                          </div>
                        </a>
                      </div>
                    )
                  })
                  return (
                    <div key={key2} className='section'>
                      <div className='title'>{linkExchangeType.label[i18n.language]}</div>
                      <div className='list'>{list}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* </div> */}
          {/* <div className='right-container'>
            <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
          </div> */}
        </div>
      </>
    )
  }
}

export default News
