import { observable, action, makeObservable, runInAction } from 'mobx'
import { fetchInitData, submitMemberForm, submitEventForm, searchPostList, searchMemberList } from '@hkpca-web/api'
import { toJS } from '@src/utils/commonUtil'
import { getLanguage, storeLanguage } from '@src/utils/commonUtil'

export default class InitDataStore {
  @observable allContents = null
  @observable homePostLists = null
  @observable latestPostLists = null
  @observable breadcrumbTitle = null
  // @observable menuList = null
  // @observable bannerList = null
  // @observable newsList = null
  // @observable eventList = null
  // @observable marketInformationList = null
  // @observable partnerList = null
  // @observable pageList = null
  @observable memberForm = {

  }
  @observable eventForm = {
    eventName: '',
    joinDate: new Date().toISOString(),
    theNumberOfParticipants: '',
    contactPersonName: '',
    contactEmail: '',
    wechatId: '',
  }
  @observable postList = null
  @observable language = 'en'
  @observable pathname = ''
  @observable offset = 0

  constructor (rootStore) {
    makeObservable(this)
    const language = getLanguage()
    runInAction(() => {
      this.rootStore = rootStore
      if (language) {
        this.language = language
      } else {
        this.language = 'en'
      }
    })
  }

  @action
  markPostList = async (pathname, offset) => {
    runInAction(() => {
      this.pathname = pathname
      this.offset = offset
    })
  }

  @action
  getInitData = async () => {
    try {
      if (!this.allContents) {
        const data = await fetchInitData()
        runInAction(() => {
          this.allContents = data.allContents
          this.homePostLists = data.homePostLists
          this.latestPostLists = data.latestPostLists
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action
  changeLanguage = async (lang) => {
    await storeLanguage(lang)
    runInAction(() => {
      this.language = lang
    })
  }

  @action
  submitMemberForm = async (formData) => {
    try {
      await submitMemberForm(formData)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  submitEventForm = async (formData) => {
    try {
      await submitEventForm(formData)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  searchPostList = async (searchText, postType, category, offset, limit) => {
    try {
      const postList = await searchPostList(searchText, postType, category, offset, limit)
      runInAction(() => {
        // this.postList = [...this.postList, ...postList]
      })
      return postList
    } catch (error) {
      console.log(error)
    }
  }

  @action
  searchMemberList = async (searchText, memberBusinessNature, memberCity, memberLocation, offset, limit) => {
    try {
      const memberList = await searchMemberList(searchText, memberBusinessNature, memberCity, memberLocation, offset, limit)
      runInAction(() => {
        // this.postList = [...this.postList, ...postList]
      })
      return memberList
    } catch (error) {
      console.log(error)
    }
  }

  @action
  getPage = async (pageIdentifier) => {
    const page = this.pageList.find((page) => page.identifier === pageIdentifier)
    return page
  }
}
