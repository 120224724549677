import React from 'react'
import { inject, observer } from 'mobx-react'
import Logo from '@hkpca-web/components/common/logo'
import HeaderMenu from '@hkpca-web/components/navigations/HeaderMenu'
import { toJS } from '@src/utils/commonUtil'
import { navigate } from "gatsby"
import Link from 'gatsby-link'
import '@hkpca-web/loaders'
import { Helmet } from "react-helmet"
import favicon from '@hkpca-web/images/favicon.png'

@inject('initDataStore')
@observer

class Test extends React.Component {
  render () {
    const { initDataStore } = this.props
    const menuList = initDataStore.allContents.informations.menu
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta charSet="utf-8" />
          <title>Hong Kong Printed Circuit Association - 香港綫路板協會 - 香港线路板协会</title>
          <link rel="canonical" href="http://mysite.com/example" />
          <link rel="icon" href={favicon} />
        </Helmet>
        <div className='header'>
          <div className='container flex-row space-between align-items-center'>
            <Link to={'/'}><div className='logo'><Logo /></div></Link>
            <HeaderMenu menuList={menuList} />
          </div>
        </div>
      </>
    )
  }
}

export default Test
