import React from 'react'
import { inject, observer } from 'mobx-react'
import BannerSection from '@hkpca-web/screens/home/sections/BannerSection'
import NewsSection from '@hkpca-web/screens/home/sections/NewsSection'
import EventSection from '@hkpca-web/screens/home/sections/EventSection'
import MarketInformationSection from '@hkpca-web/screens/home/sections/MarketInformationSection'
import PartnerSection from '@hkpca-web/screens/home/sections/PartnerSection'
// import Slide from '@material-ui/core/Slide'
// import Fade from '@material-ui/core/Fade'

@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    // this.state = {
    //   isTriggerNewsSection: false
    // }
  }

  componentDidMount () {
    // window.addEventListener("scroll", (e) => this.handleNavigation(e));
    // const root = document.querySelector('.home');
    // const options = {
    //   root,
    //   threshold: [0],
    // };
    // let callback = (entries, observer) => {
    //   entries.forEach(entry => {
    //     if (entry.isIntersecting) {
    //       console.log('test')
    //       //  只在目標元素進入 viewport 時執行這裡的工作
    //     } else {
    //       console.log('test2')
    //       // 只在目標元素離開 viewport 時執行這裡的工作
    //     }
    //   })
    // }
    // const observer = new IntersectionObserver(callback, options);
    // const target = document.querySelector('.event-section');
    // observer.observe(target)
  }

  handleNavigation = (e) => {
    // const newsSectionY = document.getElementById('news-section').getBoundingClientRect().y
    // console.log(newsSectionY)
    // if (newsSectionY < 300 && !this.state.isTriggerNewsSection) {
    //   this.setState({ isTriggerNewsSection: true })
    //   window.removeEventListener("scroll", (e) => this.handleNavigation(e));
    // }
  }

  render () {
    const { initDataStore } = this.props
    // const { isTriggerNewsSection } = this.state
    if (!initDataStore.allContents) {
      return (<></>)
    }

    const bannerList = initDataStore.allContents.informations.banner
    const newsList = initDataStore.homePostLists.news
    const eventList = initDataStore.homePostLists.event
    const marketInformationList = initDataStore.homePostLists.marketInformation
    const epPoliciesList = initDataStore.homePostLists.epPolices
    const partnerList = initDataStore.homePostLists.partner

    return (
      <>
        <div className='home'>
          <BannerSection bannerList={bannerList} />
          {/*
          <Fade in={isTriggerNewsSection}></Fade>
           */}
          <NewsSection newsList={newsList} />
          <EventSection eventList={eventList} />
          <MarketInformationSection marketInformationList={marketInformationList} epPoliciesList={epPoliciesList} />
          <PartnerSection partnerList={partnerList} />
        </div>
      </>
    )
  }
}

export default Test
