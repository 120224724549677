import React from 'react'
import { inject, observer } from 'mobx-react'
import Header from '@hkpca-web/components/common/header'
import AppBar from '@hkpca-web/components/common/appbar'
import Footer from '@hkpca-web/components/common/footer'
import CircularProgress from '@material-ui/core/CircularProgress'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import Loading from '@hkpca-web/components/common/loading'
import { toJS, getBreadcrumb } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

@inject('initDataStore')
@observer

class Layout extends React.Component {
  constructor (props) {
    super(props)
    // if (typeof window !== "undefined") {
    //   let isMobileSize = false
    //   if (window.innerWidth < 1200) {
    //     isMobileSize = true
    //   }
    //   // toJS(window.innerWidth)
    //   this.state = {
    //     menu: null,
    //     width: 0,
    //     height: 0,
    //     isMobileSize: isMobileSize
    //   }
    //   // this.isLandscape = this.props.isLandscape
    // }
  }

  updateDimensions = () => {
    let isMobileSize = false
    if (window.innerWidth <= 1200) {
      isMobileSize = true
    }
    if (this.state.isMobileSize !== isMobileSize) {
      window.location.reload()
    }
    // toJS(window.innerWidth)
    // toJS(this.state.isMobileSize)
    // toJS(isMobileSize)
    this.setState({ width: window.innerWidth, height: window.innerHeight, isMobileSize: isMobileSize })
  }

  componentDidMount () {
    this.fetchInitData()
    // window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount () {
    // window.removeEventListener('resize', this.updateDimensions)
  }

  fetchInitData = async () => {
    const { initDataStore } = this.props
    await initDataStore.getInitData()
    await this.init()
    document.body.style.backgroundColor = 'white'
  }

  init = async () => {
    const { initDataStore } = this.props
    // const menu = await getBreadcrumb(initDataStore.allContents.informations.menu, location.pathname)
    // this.setState({
    //   menu: menu
    // })
  }

  render () {
    // const { isLandscape } = this.props
    // console.log(isLandscape)
    // if (isLandscape !== this.isLandscape) {
    //   window.location.reload()
    // }
    const { initDataStore } = this.props
    // const { menu } = this.state
    if (!initDataStore.allContents) {
      return (<Loading />)
    }
    const pathname = window.location.pathname
    const pathList = window.location.pathname.split('/')
    return (
      <>
        <BrowserView viewClassName='desktop'>
          <Header />
          {
            (pathname !== '/') &&
            < Breadcrumb />
          }
          <div className='body'>
            {this.props.children}
          </div>
          <Footer />
        </BrowserView>
        <MobileView viewClassName='mobile'>
          <AppBar />
          {
            (pathname !== '/') &&
            < Breadcrumb />
          }
          <div className='body'>
            {this.props.children}
          </div>
          <Footer />
        </MobileView>
      </>
    )
  }
}

export default Layout
