import React from 'react'
import { navigate } from "gatsby"
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { toJS } from '@src/utils/commonUtil'
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone'
import Fade from '@material-ui/core/Fade'
import { withTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { inject, observer } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import ShareIcon from '@hkpca-web/components/common/WechatIcon'
// import erweiWechatQRCode from '@hkpca-web/images/qrcode-erwei.jpeg'
// import hkpcaWechatQRCode from '@hkpca-web/images/qrcode-hkpca.jpeg'
import facebookQRCode from '@hkpca-web/images/qrcode-facebook.jpeg'
import linkedinQRCode from '@hkpca-web/images/qrcode-linkedin.jpeg'
import wechatQRCode from '@hkpca-web/images/qrcode-wechat.jpeg'


@inject('initDataStore')
@observer
@withTranslation()

class HeaderMenu extends React.Component {
  constructor (props) {
    super(props)
    this.languages = {
      en: 'EN',
      tc: '繁',
      sc: '简',
    }
    this.state = {
      languageAnchorEl: null,
      langText: null,
      showQRCode: false
    }
  }

  openLanguageMenu = (event) => {
    this.setState({
      languageAnchorEl: event.currentTarget
    })
  }

  closeLanguageMenu = () => {
    this.setState({
      languageAnchorEl: null
    })
  }

  handleChangeLanguage = async (langCode, langText) => {
    const { initDataStore } = this.props
    await initDataStore.changeLanguage(langCode)
    this.props.i18n.changeLanguage(langCode)
    this.setState({
      langText: langText,
      languageAnchorEl: null
    })
  }

  toogleWechatQRCodeOnclick = async () => {
    this.setState({
      showQRCode: !this.state.showQRCode,
    })
  }

  render () {
    const { handleChangeLanguage, openLanguageMenu, closeLanguageMenu, languages, toogleWechatQRCodeOnclick } = this
    const { langText, languageAnchorEl, showQRCode } = this.state
    const { initDataStore, i18n, menuList } = this.props
    const menu = menuList.find((menu) => menu.level === 1)
    menu.level2 = menuList.filter((menu) => menu.level === 2)
    menu.level2.forEach((level2item, key) => {
      menu.level2[key]['level3'] = menuList.filter((item) => level2item.identifier === item.parentIdentifier)
    })
    const pageList = initDataStore.allContents.informations.page.filter((page) => page.customPostType !== 'SYSTEM')

    return (
      <>
        <div className='menu'>
          {
            menu.level2.map((level2item, key) => {
              return (
                <div className='dropdown' key={key}>
                  <div className='title' onClick={navigate.bind(this, `${level2item.path}`)}>{level2item.title[i18n.language]}</div>
                  <div className="dropdown-content">
                    <div className='transparent' />
                    {
                      level2item.identifier === 'event' &&
                      pageList.map((page, key2) => {
                        return (
                          <div key={key2} className='item' onClick={navigate.bind(this, `/custom-page/?identifier=${page.identifier}`)}>{page.title[i18n.language]}</div>
                        )
                      })
                    }
                    {
                      level2item.level3.map((level3item, key2) => {
                        return (
                          <div key={key2} className='item' onClick={navigate.bind(this, `${level3item.path}`)}>{level3item.title[i18n.language]}</div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
          <div className='lang'>
            <div>
              <div onClick={openLanguageMenu}>
                {`${languages[i18n.language]}`}
                <ArrowDropDownIcon />
              </div>
              <Menu
                anchorEl={languageAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                // keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(languageAnchorEl)}
                onClose={closeLanguageMenu}
                TransitionComponent={Fade}
              >
                {
                  Object.keys(languages).map((code, key) => {
                    return (< MenuItem key={key} onClick={handleChangeLanguage.bind(this, code)} >{languages[code]}</MenuItem>)
                  })
                }
              </Menu>
            </div>
          </div>
          <div className='wechat-qrcode' onClick={toogleWechatQRCodeOnclick} >
            <ShareIcon />
            {/* <img src={wechatIcon} /> */}
          </div>
          <Dialog onClose={toogleWechatQRCodeOnclick} open={showQRCode}>
            <div className='qrcode'>
              <img src={facebookQRCode} style={{ width: 200 }} />
              <img src={linkedinQRCode} style={{ width: 200 }} />
              <img src={wechatQRCode} style={{ width: 200 }} />
            </div>
          </Dialog>
        </div>
      </>
    )
  }
}

export default HeaderMenu
