import React from 'react'
import Select from '@material-ui/core/Select'
import {
  FormControl,
  MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { toJS } from '@src/utils/commonUtil'

const styles = {
  input: {
    padding: '10px 12px',
    minWidth: '160px',
    // height: '20px',
    lineHeight: '24px',
    backgroundColor: '#f2f2f2',
    borderColor: '#f2f2f2',
    border: '1px solid #f2f2f2',
    '&:focus': {
      border: '1px solid #f2f2f2',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#f2f2f2',
    },
  },
  error: {
    padding: '10px 12px',
    minWidth: '160px',
    // height: '20px',
    lineHeight: '30px',
    backgroundColor: '#f2f2f2',
    borderColor: 'red',
    border: '1px solid red',
    '&:focus': {
      border: '1px solid red',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#f2f2f2',
    }
  }
}

@withStyles(styles)

class CustomPicker extends React.Component {

  constructor (props) {
    super(props)
    let defaultValue = ''
    if (this.props.value) {
      defaultValue = this.props.value
    }
    if (this.props.field.value) {
      defaultValue = this.props.field.value
    }
    this.state = {
      value: defaultValue
    }
  }

  handleChange = (event) => {
    const value = event.target.value
    const { onSubmit, name } = this.props
    this.setState({ value: value })
    const fieldName = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(fieldName, value)
    if (onSubmit) {
      onSubmit({ [name]: value })
    }
  }

  render () {
    const { handleChange } = this
    const { errors, language = 'en', isMultilingual = false, hasNoneOption = false, hasAllOption = false, label, type, placeholder, optionList, classes } = this.props
    const name = this.props.field.name
    const { value } = this.state
    // console.log(value)

    const allOption = {
      en: 'All',
      tc: '全部',
      sc: '全部'
    }

    return (
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={handleChange}
          input={<InputBase />}
          className={(errors && errors[name]) ? classes.error : classes.input}
        >
          {
            hasNoneOption && (
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
            )
          }
          {
            hasAllOption && (
              <MenuItem value='ALL' >
                {
                  isMultilingual ? allOption[language] : allOption.en
                }
              </MenuItem>
            )
          }
          {
            optionList.map((option, key) => {
              return <MenuItem key={key} value={option.value}>{option.label[language]}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    )
  }
}

export default CustomPicker
