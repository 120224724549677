import React from 'react'
import { createBrowserHistory } from 'history'
import { Router } from '@reach/router'
import Layout from '@hkpca-web/components/common/layout'
import Home from '@hkpca-web/screens/home'
import AboutUs from '@hkpca-web/screens/aboutUs'
import ExecutiveCommittee from '@hkpca-web/screens/aboutUs/ExecutiveCommittee'
import Event from '@hkpca-web/screens/event'
import EventExhibition from '@hkpca-web/screens/event/Exhibition'
import SeminarTraining from '@hkpca-web/screens/event/SeminarTraining'
import OtherActivities from '@hkpca-web/screens/event/OtherActivities'
import Exhibition from '@hkpca-web/screens/exhibition'
import IECExhibition from '@hkpca-web/screens/exhibition/IECExhibition'
import OtherExhibition from '@hkpca-web/screens/exhibition/OtherExhibition'
import MarketingInformation from '@hkpca-web/screens/marketInformation'
import MarketNews from '@hkpca-web/screens/marketInformation/MarketNews'
import EPPolicies from '@hkpca-web/screens/marketInformation/EPPolicies'
import MarketingResearch from '@hkpca-web/screens/marketInformation/MarketingResearch'
import TechnicalArticle from '@hkpca-web/screens/marketInformation/TechnicalArticle'
import MemberList from '@hkpca-web/screens/member/MemberList'
import MemberNewsList from '@hkpca-web/screens/member/MemberNewsList'
import MemberNewsDetail from '@hkpca-web/screens/member/MemberNewsDetail'
import MemberForm from '@hkpca-web/screens/member/MemberForm'
import JournalList from '@hkpca-web/screens/other/JournalList'
import JournalDetail from '@hkpca-web/screens/other/JournalDetail'
import SurveyList from '@hkpca-web/screens/other/SurveyList'
import SurveyDetail from '@hkpca-web/screens/other/SurveyDetail'
import Photo from '@hkpca-web/screens/other/Photo'
import FormDownloadList from '@hkpca-web/screens/other/FormDownloadList'
import FormDownloadDetail from '@hkpca-web/screens/other/FormDownloadDetail'
import LinkExchange from '@hkpca-web/screens/other/LinkExchange'
import PartnerList from '@hkpca-web/screens/other/PartnerList'
import JobOpportunityList from '@hkpca-web/screens/other/JobOpportunityList'
import JobOpportunityDetail from '@hkpca-web/screens/other/JobOpportunityDetail'
import ContactUs from '@hkpca-web/screens/other/ContactUs'
import SearchResult from '@hkpca-web/screens/other/SearchResult'
import PostList from '@hkpca-web/screens/other/PostList'
import PostDetail from '@hkpca-web/screens/other/PostDetail'
import CustomPage from '@hkpca-web/screens/other/CustomPage'
import CustomList from '@hkpca-web/screens/other/CustomList'
import EventForm from '@hkpca-web/screens/member/EventForm'
import Disclaimer from '@hkpca-web/screens/other/Disclaimer'

import { Provider } from 'mobx-react'
import RootStore from '@hkpca-web/stores/RootStore'

const rootStore = new RootStore()

const router = () => {
  return (
    <Provider {...rootStore}>
      <RoutesLayout />
    </Provider>
  )
}
class RoutesLayout extends React.Component {
  render () {
    return (
      <Layout>
        <Router>
          <Home path='/' />

          <AboutUs path='/about-us' />
          <AboutUs path='/about-us/about-hkpca' />
          <AboutUs path='/about-us/message-from-the-president' />
          
          <ExecutiveCommittee path='/about-us/executive-committee' />
          <AboutUs path='/about-us/article-of-association' />

          <PostList path='/news' />

          <PostList path='/event' />
          <PostList path='/event/exhibition' />
          <PostList path='/event/seminar-training' />
          <PostList path='/event/other-activities' />

          <PostList path='/exhibition' />
          <PostList path='/exhibition/iec-exhibition' />
          <PostList path='/exhibition/other-exhibition' />

          <MemberList path='/member' />
          <MemberList path='/member/member-list' />
          <MemberNewsList path='/member/member-news/list' />
          <PostDetail path='/member/member-news/detail' />
          <MemberForm path='/member/join-us' />
          <EventForm path='/member/join-event' />

          <PostList path='/market-information' />
          <PostList path='/market-information/market-news' />
          <PostList path='/market-information/ep-policies' />
          <PostList path='/market-information/marketing-research' />
          <PostList path='/market-information/technical-article' />

          <FormDownloadList path='/form-download' />
          <FormDownloadDetail path='/form-download-detail' />
          <JobOpportunityList path='/job-opportunities' />
          <JobOpportunityDetail path='/job-opportunity-detail' />

          <JournalDetail path='/journal' />
          <JournalDetail path='/journal-detail' />

          <SurveyDetail path='/survey' />
          <SurveyDetail path='/survey-detail' />

          {/* <Photo path='/photo' /> */}
          <LinkExchange path='/link-exchange' />
          <PartnerList path='/partner' />
          <ContactUs path='/contact-us' />
          <Disclaimer path='/disclaimer' />
          <SearchResult path='/search-result' />

          <PostDetail path='/news/detail' />
          <PostDetail path='/event/detail' />
          <PostDetail path='/exhibition/detail' />
          <PostDetail path='/market-information/detail' />
          <PostDetail path='/post/detail' />

          <CustomPage path='/custom-page' />
          <CustomList path='/custom-list' />
          <PostDetail path='/custom-post' />
        </Router>
      </Layout>
    )
  }
}

export default router
