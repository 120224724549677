import React, { useState } from 'react'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import { CustomGallery, Item, DefaultLayout } from 'react-photoswipe-gallery'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"

export default function CustomPhotoswipeGallery (props) {
  const { imageList } = props
  const layoutRef = React.createRef()
  return (
    <div className='photoswipe-gallery'>
      <CustomGallery layoutRef={layoutRef} ui={PhotoswipeUIDefault}>
        {
          imageList.map((image, key) => {
            return (
              <BrowserView key={key}>
                <Item
                  key={key}
                  original={image.original.replace('http://', 'https://')}
                  thumbnail={image.original.replace('http://', 'https://')}
                  width='1120'
                  height='720'
                // title='Image 1'
                >
                  {({ ref, open }) => (
                    <div className='image'>
                      <img ref={ref} onClick={open} src={image.original.replace('http://', 'https://')} />
                    </div>
                  )}
                </Item>
              </BrowserView>
            )
          })
        }
        {
          imageList.map((image, key) => {
            return (
              <MobileView key={key}>
                <Item
                  key={key}
                  original={image.original.replace('http://', 'https://')}
                  thumbnail={image.original.replace('http://', 'https://')}
                  width='280'
                  height='180'
                // title='Image 1'
                >
                  {({ ref, open }) => (
                    <div className='image'>
                      <img ref={ref} onClick={open} src={image.original.replace('http://', 'https://')} />
                    </div>
                  )}
                </Item>
              </MobileView>
            )
          })
        }
        <DefaultLayout
          shareButton={false}
          // fullscreenButton={false}
          // zoomButton={false}
          ref={layoutRef}
        />
      </CustomGallery>
    </div>
  )
}
