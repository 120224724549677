import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import NewsList from '@hkpca-web/components/templates/NewsList'
import { trimHtmlTag, toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
// import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

@inject('initDataStore')
@observer
@withTranslation()

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      category: ''
    }
  }

  render () {
    const { initDataStore } = this.props
    const memmberNewsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MEMBER_NEWS')
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    const postTypes = initDataStore.allContents.categories.postTypes
    return (
      <>
        <div className='member-news-list'>
          <div className='responsive-container'>
            <div className='left-container'>
              <NewsList list={memmberNewsList} />
            </div>
            <div className='right-container'>
              <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
