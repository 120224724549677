import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import CustomImageGallery from '@hkpca-web/components/viewers/CustomImageGallery'
import CustomPhotoswipeGallery from '@hkpca-web/components/viewers/CustomPhotoswipeGallery'
import { t, toJS, trimHtmlTag } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { navigate } from 'gatsby'
import envConfig from '@src/configs/envConfig'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { Player } from 'video-react'
// import "video-react/dist/video-react.css"

@withTranslation()
@inject('initDataStore')
@observer

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null,
      isReady: false,
      photoIndex: 0,
      isOpen: false,
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    this.setState({
      id: parseInt(parsed.id),
      isReady: true
    })
  }

  componentDidUpdate () {
    const parsed = queryString.parse(window.location.search)
    const postId = parseInt(parsed.id)
    if (this.state.id !== postId) {
      this.setState({
        id: postId,
        isReady: false
      }, () => {
        this.setState({
          isReady: true
        })
      })
    }
  }

  render () {
    const { isReady, isOpen, photoIndex } = this.state
    const { initDataStore, path, i18n } = this.props

    if (!this.state.id) {
      return (<></>)
    }
    const news = initDataStore.allContents.informations.post.find((post) => post.id === this.state.id)
    const images = news.imageAlbum.map((image) => image.original.replace('http://', 'https://'))
    const thumbs = news.imageAlbum.map((image) => image.thumbnail.replace('http://', 'https://'))
    const videos = news.videoAlbum ? news.videoAlbum.map((video) => video.original.replace('http://', 'https://')) : []

    return (
      <>
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='post-detail'>
              <div className='back-button' onClick={() => navigate(-1)}>{`< ${i18n.t('other.back')}`}</div>
              <div className='title'>{news.title[i18n.language]}</div>
              <div className='date'>
                {
                  news.displayDateTime &&
                  moment(news.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                }
              </div>
              <div className='content'>
                <div className='video-album'>
                  {
                    videos.map((video, key) => {
                      return (
                        <div className='video' key={key}>
                          {/* <video className='videoTag'>
                              <source src={`${video}`} type='video/mp4' />
                            </video> */}
                          {
                            <Player
                              playsInline
                              // poster="/assets/poster.png"
                              src={video}
                            />
                          }
                        </div>
                      )
                    })
                  }
                </div>
                {
                  // <button type="button" onClick={() => this.setState({ isOpen: true })}>
                  //   Open Lightbox
                  // </button>
                  // <CustomImageGallery imageList={news.image} />
                  // isReady && news.imageAlbum &&
                  // <CustomPhotoswipeGallery imageList={news.imageAlbum} />
                }
                {
                  trimHtmlTag(news.content[i18n.language]) !== '' &&
                  <div className='rich-editor' dangerouslySetInnerHTML={{ __html: news.content[i18n.language] }} />
                }
                {
                  news.download.length > 0 &&
                  <div className='download-link flex-row'>
                    <a href={`${news.download[0].original.replace('http://', 'https://')}`} target="_blank" download>{i18n.t('post.download')}</a>
                  </div>
                }
                <div className='image-album'>
                  {
                    thumbs.map((thumb, key) => {
                      return (
                        <div className='image' key={key} onClick={() => {
                          this.setState({
                            isOpen: true,
                            photoIndex: (key) % thumbs.length,
                          })
                        }}>
                          <img src={thumb} />
                        </div>
                      )
                    })
                  }
                </div>
                {
                  isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % images.length,
                        })
                      }
                    />
                  )
                }
              </div>
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList
              newsList={initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')}
              eventList={initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')}
              marketInformationList={initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')}
            />
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
