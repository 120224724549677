import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { toJS, trimHtmlTag } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import CustomPdfViewer from '@hkpca-web/components/viewers/CustomPdfViewer'
import {
  Button,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { navigate } from "gatsby"

@withTranslation()
@inject('initDataStore')
@observer

class PostDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: null
    }
  }

  redirectUrl = async () => {

  }

  render () {
    const { data, i18n } = this.props
    // toJS(data)
    return (
      <>
        <div className='page'>
          <div className='content'>
            {
              trimHtmlTag(data.content[i18n.language]) !== '' &&
              <div className='rich-editor'>
                <div dangerouslySetInnerHTML={{ __html: data.content[i18n.language] }} />
              </div>
            }
            {
              // data.document.length > 0 &&
              // <div className='document'>
              //   {/* <CustomPdfViewer file={data.document[0]['original']} /> */}
              // </div>
            }
            {
              (data.leftButtonUrl && data.leftButtonUrl !== '') && (data.rightButtonUrl && data.rightButtonUrl !== '') ?
                <div className='multiple-button-container'>
                  <a href={data.leftButtonUrl}>
                    <Button type='submit' className='left-button' disableElevation variant='contained' color='primary'>{data.leftButtonText[i18n.language]}</Button>
                  </a>
                  <a href={data.rightButtonUrl}>
                    <Button type='submit' className='right-button' disableElevation variant='contained' color='primary'>{data.rightButtonText[i18n.language]}</Button>
                  </a>
                </div>
                :
                <div className='single-button-container'>
                  {
                    data.leftButtonUrl && data.leftButtonUrl !== '' &&
                    <a href={data.leftButtonUrl}>
                      <Button type='submit' className='left-button' disableElevation variant='contained' color='primary'>{data.leftButtonText[i18n.language]}</Button>
                    </a>
                  }
                  {
                    data.rightButtonUrl && data.rightButtonUrl !== '' &&
                    <a href={data.rightButtonUrl}>
                      <Button type='submit' className='right-button' disableElevation variant='contained' color='primary'>{data.rightButtonText[i18n.language]}</Button>
                    </a>
                  }
                </div>
            }
          </div>
        </div>
      </>
    )
  }
}

export default PostDetail
