import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import envConfig from '@src/configs/envConfig'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import Skeleton from '@material-ui/lab/Skeleton'
import Link from 'gatsby-link'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <ChevronLeftIcon
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <ChevronRightIcon
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

class Banner extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoaded: false,
      showDots: false
    }
  }

  handleImageLoading = () => {
    this.setState({
      isLoaded: true,
      showDots: true
    })
  }

  render () {
    const { bannerList } = this.props
    const { handleImageLoading } = this
    const { isLoaded, showDots } = this.state
    const settings = {
      dots: showDots,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 500,
      arrows: showDots,
      adaptiveHeight: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }
    return (
      <>
        <div className='banner-section'>
          <BrowserView>
            <div className='slider-container'>
              <Slider {...settings}>
                {
                  bannerList.map((banner, key) => {
                    return (
                      <div key={key}>
                        {
                          banner.image.length > 0 &&
                          <>
                            {
                              isLoaded ? null :
                                <Skeleton
                                  animation="pulse"
                                  variant="rect"
                                  height={500}
                                />
                            }
                            <a href={banner.redirectUrl} target="_blank">
                              <div
                                className='image'
                                style={isLoaded ? {} : { display: 'none' }}
                              >
                                <img
                                  src={`${banner.image[0].original.replace('http://', 'https://')}`}
                                  onLoad={handleImageLoading}
                                />
                              </div>
                            </a>
                          </>
                        }
                        {
                          banner.video.length > 0 &&
                          <>
                            <a href={banner.redirectUrl} target="_blank">
                              <div className='video'>
                                <video className='videoTag' autoPlay loop muted>
                                  <source src={`${banner.video[0].original.replace('http://', 'https://')}`} type='video/mp4' />
                                </video>
                              </div>
                            </a>
                          </>
                        }
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </BrowserView>
          <MobileView>
            <div className='slider-container'>
              <Slider {...settings}>
                {
                  bannerList.map((banner, key) => {
                    return (
                      <div key={key}>
                        {
                          banner.image.length > 0 &&
                          <>
                            <a href={banner.redirectUrl} target="_blank">
                              <div
                                className='image'
                                style={isLoaded ? {} : { display: 'none' }}
                              >
                                <img
                                  src={`${banner.image[0].original.replace('http://', 'https://')}`}
                                  onLoad={handleImageLoading}
                                />
                              </div>
                            </a>
                          </>
                        }
                        {
                          banner.video.length > 0 &&
                          <>
                            <a href={banner.redirectUrl} target="_blank">
                              <div className='video'>
                                <video className='videoTag' autoPlay loop muted>
                                  <source src={`${banner.video[0].original.replace('http://', 'https://')}`} type='video/mp4' />
                                </video>
                              </div>
                            </a>
                          </>
                        }
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </MobileView>
        </div>
      </>
    )
  }
}

export default Banner
