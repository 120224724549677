import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import PostList from '@hkpca-web/components/templates/PostList'
import { trimHtmlTag, toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import Collapse from '@material-ui/core/Collapse'
import { navigate } from 'gatsby-link'

@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      postTypeList: props.initDataStore.allContents.categories.exhibitionType,
      postTypeKey: 'exhibitionType',
      basePath: 'exhibition',
      postType: 'EXHIBITION',
      category: 'IEC_EXHIBITION',
      collapseStateList: null
    }
  }

  componentDidMount () {
    const { initDataStore } = this.props
    const { postType } = this
    const { postTypeKey } = this.state
    const allPosts = initDataStore.allContents.informations.post.filter((post) => post.type === postType)
    let list = allPosts
    if (this.state.category !== 'ALL') {
      list = allPosts.filter((post) => post[postTypeKey] === this.state.category)
    }
    const collapseStateList = Array.from({ length: list.length }, () => false)
    this.setState({
      collapseStateList: collapseStateList
    })
  }

  tooglePostDetail = (key) => {
    const { initDataStore } = this.props
    const collapseStateList = this.state.collapseStateList
    collapseStateList[key] = !collapseStateList[key]
    this.setState({
      collapseStateList: collapseStateList
    })
  }

  handleChange = (value) => {
    const { basePath, postTypeList } = this.state
    const postType = postTypeList.find((type) => type.value === value)
    if (!postType) {
      navigate(`/${basePath}`)
    } else {
      navigate(`/${basePath}${postType.path}`)
    }
  }

  render () {
    const { initDataStore } = this.props
    const { tooglePostDetail, handleChange } = this
    const { postTypeKey, postTypeList, postType, category, collapseStateList } = this.state
    if (!collapseStateList) {
      return (<></>)
    }
    const allPosts = initDataStore.allContents.informations.post.filter((post) => post.type === postType)
    let list = allPosts
    if (category !== 'ALL') {
      list = allPosts.filter((post) => post[postTypeKey] === category)
    }
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKETING_RESEARCH')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='exhibition-post-list'>
          <div className='responsive-container'>
            <div className='left-container'>
              <div className='filter flex-row align-items-center'>
                <div className='title'>Categories</div>
                <div className='category-picker'><CategoryPicker optionList={postTypeList} value={this.state.category} onSubmit={handleChange} hasNoneOption={false} hasAllOption={true} /></div>
              </div>
              <div className='list'>
                <PostList path={'/exhibition/detail'} list={list} />
              </div>
            </div>
            <div className='right-container'>
              <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
