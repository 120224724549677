import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { navigate } from 'gatsby-link'
import Skeleton from '@material-ui/lab/Skeleton'
import { getPostDetailPath } from '@src/utils/hkpcaUtil'

@withTranslation()

class News extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClick = (postId, postType, redirectUrl) => {
    const postDetailPath = getPostDetailPath(postType)
    const url = `${postDetailPath}?id=${postId}`
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    } else {
      navigate(url)
    }
  }

  render () {
    const { handleClick } = this
    const { i18n, marketInformationList, epPoliciesList } = this.props
    return (
      <>
        <div className='market-information-section'>
          <div className='section1'>
            <div className='container'>
              <div className='section-title'>{i18n.t('home.marketInformationTitle')}</div>
              <div className='flex-row wrap'>
                {
                  marketInformationList.map((post, key) => {
                    return (
                      <div className='market-information' key={key}>

                        <div className='card btn' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>
                          <div className='image-container'>
                            <div className='image'>
                              {
                                post.image && post.image[0]
                                  ?
                                  <img src={post.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                                  :
                                  <Skeleton
                                    animation={false}
                                    variant="rect"
                                    height={500}
                                  />
                              }
                            </div>
                          </div>
                          <div className='date'>
                            {
                              post.displayDateTime &&
                              moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                            }
                          </div>
                          <div className='title truncate-overflow'>
                            {post.title[i18n.language]}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className='section2'>
            <div className='container'>
              <div className='section-title epn'>{i18n.t('home.environmentalProtectionNewsTitle')}</div>
              <div className='flex-row wrap'>
                {
                  epPoliciesList.map((post, key) => {
                    // toJS(news)
                    return (
                      <div key={key}>
                        <div className='card btn' onClick={navigate.bind(this, `/market-information/detail?id=${post.id}`)}>
                          <div className='image-container'>
                            <div className='image'>
                              <img src={post.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                            </div>
                          </div>
                          <div className='date'>
                            {
                              post.displayDateTime &&
                              moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                            }
                          </div>
                          <div className='title truncate-overflow'>
                            {post.title[i18n.language]}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className='view-all-button' onClick={navigate.bind(this, '/market-information')}>{i18n.t('home.viewAll')}</div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default News
