import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { t, toJS, trimHtmlTag, getBreadcrumb } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
import Page from '@hkpca-web/components/templates/Page'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'

@inject('initDataStore')
@observer

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      identifier: null,
      isReady: false
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    this.setState({
      identifier: parsed.identifier,
      isReady: true
    })
  }

  componentDidUpdate () {
    const parsed = queryString.parse(window.location.search)
    const identifier = parsed.identifier
    if (this.state.identifier !== identifier) {
      this.setState({
        identifier: identifier,
        isReady: false
      }, () => {
        this.setState({
          isReady: true
        })
        // const { structureStore } = this.props
        // structureStore.fetchStructureDetail(structureId)
      })
    }
  }

  render () {
    const { initDataStore } = this.props
    if (!this.state.identifier) {
      return (<></>)
    }
    const data = initDataStore.allContents.informations.page.find((page) => page.identifier === this.state.identifier)
    return (
      <>
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='about-us'>
              {
                data &&
                <Page data={data} />
              }
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList
              newsList={initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')}
              eventList={initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')}
              marketInformationList={initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')}
            />
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
