import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby-link'
import { withTranslation } from 'react-i18next'

import CloseIcon from '@material-ui/icons/Close';
import Wowbook from '@hkpca-web/components/viewers/Wowbook'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null,
      isReady: false,
      year: 'ALL',
      showModal: false,
      pdfUrl: "",
      desc: "",
      download: ""
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    if (parsed.id) {
      this.setState({
        id: parseInt(parsed.id),
        isReady: true
      })
    } else {
      const { initDataStore } = this.props
      const firstItem = initDataStore.allContents.informations.survey[0]
      this.setState({
        id: firstItem.id,
        isReady: true
      })
    }
  }

  componentDidUpdate () {
    const parsed = queryString.parse(window.location.search)
    const postId = parseInt(parsed.id)
    if (postId && this.state.id !== postId) {
      this.setState({
        id: postId,
        isReady: false
      }, () => {
        this.setState({
          isReady: true
        })
      })
    }
  }

  getYearOptionList = () => {
    const { initDataStore, i18n } = this.props
    const surveyList = initDataStore.allContents.informations.survey
    let yearList = []
    surveyList.forEach((survey) => {
      const year = moment(survey.date).format('YYYY')
      if (!yearList.includes(year)) {
        yearList.push(year)
      }
    })
    const yearOptionList = yearList.map((year) => {
      return { label: year, value: year }
    })
    return yearOptionList
  }

  getSurveyList = () => {
    const { initDataStore } = this.props
    const { year } = this.state
    let surveyList = []
    if (year === 'ALL') {
      surveyList = initDataStore.allContents.informations.survey
    } else {
      surveyList = initDataStore.allContents.informations.survey.filter((survey) => {
        return survey.year === year
      })
    }
    surveyList = surveyList.filter((survey) => survey.id !== this.state.id)
    return surveyList
  }

  handleChange = (value) => {
    this.setState({ year: value.categories })
  }

  closeModal = (url) => {
    this.setState({ showModal: false})
  }

  handleClick = ( download, desc, undownloadable ) => {    
    let pdfUrl = download[0].original.replace('http://', 'https://')
    let link = undownloadable === true ? '' : download
    if(desc){
      this.setState({ showModal: true, pdfUrl: pdfUrl, desc: desc.replace(/\n/g,'|'), download: link})
    } else {
      this.setState({ showModal: true, pdfUrl: pdfUrl, desc: '', download: link})
    }
  }


  render () {
    const { handleChange, getYearOptionList, getSurveyList,  handleClick, closeModal } = this
    const { initDataStore, i18n } = this.props
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    if (!this.state.isReady) {
      return (<></>)
    }
    const surveyList = initDataStore.allContents.informations.survey
    // const othersurveyList = initDataStore.allContents.informations.survey.filter((survey) => survey.id !== this.state.id)
    const othersurveyList = getSurveyList()
    const currentsurvey = surveyList.find((survey) => survey.id === this.state.id)
    const yearOptionList = initDataStore.allContents.categories.surveyYear
    // toJS(surveyList)
    // toJS(this.state.id)
    return (
      <>
        {this.state.showModal && <Wowbook file={this.state.pdfUrl} desc={this.state.desc} download={this.state.download} lang={i18n.language}/>}
        {this.state.showModal && <div style={{position:'fixed',right:10,top:10,zIndex:9999,color:'white'}} onClick={closeModal.bind(this)}><CloseIcon /></div> }

        <div className='survey-container'>
          <div className='responsive-container'>
            <div className='left-container'>
              <div className='filter flex-row align-items-center'>
                <div className='title'>{i18n.t('other.categories')}</div>
                <div className='category-picker'>
                  <CategoryPicker
                    name='categories'
                    optionList={yearOptionList}
                    value={this.state.year}
                    onSubmit={handleChange}
                    hasNoneOption={false}
                    hasAllOption={true}
                    language={i18n.language}
                  />
                </div>
              </div>
              <div className='survey-detail'>
                <div className='image'>
                  <img src={currentsurvey.image[0].original.replace('http://', 'https://')} alt="" />
                </div>
                <div className='detail'>
                  <div className='title'>
                    {currentsurvey.title[i18n.language]}
                  </div>
                  <div className={currentsurvey.download.length > 0 ? 'description' : 'normal-description'}>
                    {currentsurvey.description[i18n.language]}
                  </div>
                  {
                    currentsurvey.download.length > 0 && (
                      <div className='btn-container'>
                        <a onClick={handleClick.bind(this, currentsurvey.download, currentsurvey.toc[i18n.language], currentsurvey.isUndownloadable )} >
                          <div className='details-btn' >
                            {i18n.t('post.details')}
                          </div>
                        </a>
                      </div>
                    )
                  }
                </div>
              </div>
              <hr />
              <div className='survey-list'>
                {
                  othersurveyList.map((survey, key) => {
                    return (
                      <div className='card' key={key}>
                        <a >
                          <div className='image'>
                            <img src={survey.image[0].original.replace('http://', 'https://')} alt="" />
                          </div>
                          <div>
                            <div className='title'>
                              {survey.title[i18n.language]}
                            </div>
                            {
                              survey.download.length > 0 && (
                                survey.toc ? (
                                  <div className='btn-container'>
                                  <a onClick={handleClick.bind(this, survey.download, survey.toc[i18n.language],  survey.isUndownloadable)}>
                                    <div className='details-btn' >
                                      {i18n.t('post.details')}
                                    </div>
                                  </a>
                                </div>
                                ) : (
                                  <div className='btn-container'>
                                  <a onClick={handleClick.bind(this, survey.download, survey.toc,  survey.isUndownloadable)}>
                                    <div className='details-btn' >
                                      {i18n.t('post.details')}
                                    </div>
                                  </a>
                                </div>
                                )
                              )
                            }
                          </div>
                        </a>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='right-container'>
              <LatestPostList
                  newsList={newsList.slice(0, 3)}
                  eventList={eventList.slice(0, 3)}
                  marketInformationList={marketInformationList.slice(0, 3)}
                />
            
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test