import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { t, toJS, getBreadcrumb } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
import envConfig from '@src/configs/envConfig'
import Logo from '@hkpca-web/images/logo-color.png'
import Collapse from '@material-ui/core/Collapse'
import { withTranslation } from 'react-i18next'

@inject('initDataStore')
@observer
@withTranslation()

// https://hkpca-web.web.app/about-us/executive-committee/?s=1
// https://hkpca-web.web.app/about-us/executive-committee/?s=2&c1=f

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    const parsed = queryString.parse(window.location.search)
    const c1 = parsed.c1 === 'f'
    const s = parsed.s
    this.state = {
      isThe10thExecutiveCommitteeMembersCollapse: c1,
      isExecutiveCommitteeMembersCollapse: false,
      scrollToSection: s,
    }
  }

  componentDidMount () {
    this.scrollToBottom()
  }

  componentDidUpdate () {
    this.scrollToBottom()
  }

  scrollToBottom = () => {
    switch (this.state.scrollToSection) {
      case '1':
        this.s1.scrollIntoView({ behavior: "smooth" })
        break
      case '2':
        this.s2.scrollIntoView({ behavior: "smooth" })
        break
      default:
        break
    }
  }

  toogleThe10thExecutiveCommitteeMembersCollapse = () => {
    this.setState({ isThe10thExecutiveCommitteeMembersCollapse: !this.state.isThe10thExecutiveCommitteeMembersCollapse })
  }

  toogleExecutiveCommitteeMembersCollapse = () => {
    this.setState({ isExecutiveCommitteeMembersCollapse: !this.state.isExecutiveCommitteeMembersCollapse })
  }

  render () {
    const { initDataStore, i18n } = this.props
    const honoraryFoundingChairmanMaster = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'honoraryFoundingChairmanMaster')
    const honoraryFoundingChairmanMasterList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'honoraryFoundingChairmanMaster').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const honoraryChairman = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'honoraryChairman')
    const honoraryChairmanList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'honoraryChairman').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const honoraryFoundingChairman = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'honoraryFoundingChairman')
    const honoraryFoundingChairmanList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'honoraryFoundingChairman').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const honoraryECMembers = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'honoraryECMembers')
    const honoraryECMembersList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'honoraryECMembers').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const chairman = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'chairman')
    const chairmanList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'chairman').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const viceChairmanSecretaryGeneral = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'viceChairmanSecretaryGeneral')
    const viceChairmanSecretaryGeneralList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'viceChairmanSecretaryGeneral').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const viceChairmanTreasurer = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'viceChairmanTreasurer')
    const viceChairmanTreasurerList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'viceChairmanTreasurer').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const viceChairman = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'viceChairman')
    const viceChairmanList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'viceChairman').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    const executiveCommitteeMembers = initDataStore.allContents.categories.committeePositions.find((position) => position.value === 'executiveCommitteeMembers')
    const executiveCommitteeMembersList = initDataStore.allContents.informations.committee.filter((committee) => committee.position === 'executiveCommitteeMembers').sort(function (a, b) {
      return a.sequence - b.sequence
    })
    return (
      <>
        {/* <div className='about-us'>
          <div className='container'>
            <div className='title'>{page.title[i18n.language]}</div>
            <div className='description'>{page.title[i18n.language]}</div>
            <div className='text'>
              <div dangerouslySetInnerHTML={{ __html: page.content[i18n.language] }} />
            </div>
          </div>
        </div> */}
        <div className='committee'>
          <div className='container'>
            <div ref={(el) => { this.s1 = el }}></div>
            <div className='grid'>
              <div className='committee-logo'><img src={Logo} /></div>
              <div className='committee-position' style={{ width: 260 }}>{honoraryFoundingChairmanMaster.label[i18n.language]}</div>
              <div className='honorary-founding-chairman-master'>
                {
                  honoraryFoundingChairmanMasterList.map((committee, key) => {
                    return (
                      <div className='normal-card' key={key}>
                        <div className='image'>
                          {
                            committee.image[0] &&
                            <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                          }
                        </div>
                        <div className='card-body'>
                          <div className='name'>{committee.name[i18n.language]}</div>
                          <div className='title'>{committee.title[i18n.language]}</div>
                          <div className='company'>{committee.company[i18n.language]}</div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className='committee-position'>{honoraryChairman.label[i18n.language]}</div>
              <div className='honorary-founding-chairman-master'>
                {
                  honoraryChairmanList.map((committee, key) => (
                    <div className='normal-card' key={key}>
                      <div className='image'>
                        {
                          committee.image[0] &&
                          <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                        }
                      </div>
                      <div className='card-body'>
                        <div className='name'>{committee.name[i18n.language]}</div>
                        <div className='title'>{committee.title[i18n.language]}</div>
                        <div className='company'>{committee.company[i18n.language]}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className='committee-position'>{honoraryFoundingChairman.label[i18n.language]}</div>
              <div className='honorary-founding-chairman-master'>
                {
                  honoraryFoundingChairmanList.map((committee, key) => (
                    <div className='small-card' key={key}>
                      <div className='image'>
                        {
                          committee.image[0] &&
                          <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                        }
                      </div>
                      <div className='card-body'>
                        <div className='name'>{committee.name[i18n.language]}</div>
                        <div className='title'>{committee.title[i18n.language]}</div>
                        <div className='company'>{committee.company[i18n.language]}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className='committee-position'>{honoraryECMembers.label[i18n.language]}</div>
              <div className='honorary-founding-chairman-master'>
                {
                  honoraryECMembersList.map((committee, key) => (
                    <div className='small-card' key={key}>
                      <div className='image'>
                        {
                          committee.image[0] &&
                          <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                        }
                      </div>
                      <div className='card-body'>
                        <div className='name'>{committee.name[i18n.language]}</div>
                        <div className='title'>{committee.title[i18n.language]}</div>
                        <div className='company'>{committee.company[i18n.language]}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <hr />
            <div ref={(el) => { this.s2 = el }}></div>
            <div className='collapse-title' onClick={this.toogleThe10thExecutiveCommitteeMembersCollapse}>{i18n.t('aboutUs.theExecutiveCommitteeMembers')}</div>
            <Collapse in={this.state.isThe10thExecutiveCommitteeMembersCollapse} collapsedHeight={20}>
              <div className='grid'>
                <div className='committee-position'>{chairman.label[i18n.language]}</div>
                <div className='honorary-founding-chairman-master'>
                  {
                    chairmanList.map((committee, key) => (
                      <div className='normal-card' key={key}>
                        <div className='image'>
                          {
                            committee.image[0] &&
                            <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                          }
                        </div>
                        <div className='card-body'>
                          <div className='name'>{committee.name[i18n.language]}</div>
                          <div className='title'>{committee.title[i18n.language]}</div>
                          <div className='company'>{committee.company[i18n.language]}</div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className='vice-chairman-secretary-general'>
                  <div>
                    <div className='committee-position' style={{ width: 260 }}>{viceChairmanSecretaryGeneral.label[i18n.language]}</div>
                    <div className='honorary-founding-chairman-master'>
                      {
                        viceChairmanSecretaryGeneralList.map((committee, key) => (
                          <div className='normal-card' key={key}>
                            <div className='image'>
                              {
                                committee.image[0] &&
                                <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                              }
                            </div>
                            <div className='card-body'>
                              <div className='name'>{committee.name[i18n.language]}</div>
                              <div className='title'>{committee.title[i18n.language]}</div>
                              <div className='company'>{committee.company[i18n.language]}</div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div>
                    <div className='committee-position' style={{ width: 260 }}>{viceChairmanTreasurer.label[i18n.language]}</div>
                    <div className='honorary-founding-chairman-master'>
                      {
                        viceChairmanTreasurerList.map((committee, key) => (
                          <div className='normal-card' key={key}>
                            <div className='image'>
                              {
                                committee.image[0] &&
                                <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                              }
                            </div>
                            <div className='card-body'>
                              <div className='name'>{committee.name[i18n.language]}</div>
                              <div className='title'>{committee.title[i18n.language]}</div>
                              <div className='company'>{committee.company[i18n.language]}</div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div className='committee-position'>{viceChairman.label[i18n.language]}</div>
                <div className='honorary-founding-chairman-master'>
                  {
                    viceChairmanList.map((committee, key) => (
                      <div className='normal-card' key={key}>
                        <div className='image'>
                          {
                            committee.image[0] &&
                            <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                          }
                        </div>
                        <div className='card-body'>
                          <div className='name'>{committee.name[i18n.language]}</div>
                          <div className='title'>{committee.title[i18n.language]}</div>
                          <div className='company'>{committee.company[i18n.language]}</div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </Collapse>
            <hr />
            <div className='collapse-title' onClick={this.toogleExecutiveCommitteeMembersCollapse}>{i18n.t('aboutUs.executiveCommitteeMembers')}</div>
            <Collapse in={this.state.isExecutiveCommitteeMembersCollapse} collapsedHeight={20}>
              <div className='executive-committee-members grid' style={{ marginTop: 40 }}>
                {
                  executiveCommitteeMembersList.map((committee, key) => (
                    <div className='small-card' key={key}>
                      <div className='image'>
                        {
                          committee.image[0] &&
                          <img src={`${committee.image[0].original.replace('http://', 'https://')}`} />
                        }
                      </div>
                      <div className='card-body'>
                        <div className='name'>{committee.name[i18n.language]}</div>
                        <div className='title'>{committee.title[i18n.language]}</div>
                        <div className='company'>{committee.company[i18n.language]}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Collapse>
            <hr />
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
