import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby-link'
import { withTranslation } from 'react-i18next'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      year: 'ALL'
    }
  }

  getYearOptionList = () => {
    const { initDataStore, i18n } = this.props
    const journalList = initDataStore.allContents.informations.journal
    let yearList = []
    journalList.forEach((journal) => {
      const year = moment(journal.date).format('YYYY')
      if (!yearList.includes(year)) {
        yearList.push(year)
      }
    })
    const yearOptionList = yearList.map((year) => {
      return {
        label: {
          en: year,
          tc: year,
          sc: year
        }, value: year
      }
    })
    return yearOptionList
  }

  getJournalList = () => {
    const { initDataStore } = this.props
    const { year } = this.state
    let journalList = []
    if (year === 'ALL') {
      journalList = initDataStore.allContents.informations.journal
    } else {
      // toJS(year)
      journalList = initDataStore.allContents.informations.journal.filter((journal) => {
        // toJS(moment(journal.date).format('YYYY'))
        return moment(journal.date).format('YYYY') === year
      })
    }
    return journalList
  }

  handleChange = (value) => {
    // toJS(value)
    this.setState({ year: value.categories })
    // const { initDataStore, i18n } = this.props
    // const journalList = initDataStore.allContents.informations.journal
    // this.getYearOptionList()

    // const yearOptionList = journalList.filter((journal)=> === new Date(journal.date).getFullYear())
    // toJS(journalList)
    // const { initDataStore } = this.props
    // this.setState(value, async () => {
    //   const { searchText, memberBusinessNature, memberCity, memberLocation, offset, limit } = this.state
    //   const data = await initDataStore.searchMemberList(
    //     searchText,
    //     memberBusinessNature,
    //     memberCity,
    //     memberLocation,
    //     offset,
    //     limit
    //   )
    //   this.setState({ list: data })
    // })
  }

  render () {
    const { handleChange, getYearOptionList, getJournalList } = this
    const { initDataStore, i18n } = this.props
    const journalList = getJournalList()
    const currentJournal = journalList[0]
    // toJS(currentJournal)
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    const yearOptionList = getYearOptionList()
    return (
      <>
        <div className='journal-container'>
          {/* <div className='container flex-row space-between'> */}
          <div className='responsive-container'>
            <div className='left-container'>
              <div className='filter flex-row align-items-center'>
                <div className='title'>{i18n.t('other.categories')}</div>
                <div className='category-picker'>
                  <CategoryPicker
                    name='categories'
                    optionList={yearOptionList}
                    value={this.state.year}
                    onSubmit={handleChange}
                    hasNoneOption={false}
                    hasAllOption={true}
                    language={i18n.language}
                  />
                </div>
              </div>
              <div className='journal-detail flex-row'>
                <div className='image'>
                  <img src={currentJournal.image[0].original.replace('http://', 'https://')} alt="" />
                </div>
                <div className='detail'>
                  <div className='title'>
                    {currentJournal.title[i18n.language]}
                  </div>
                  <div className='description'>
                    {currentJournal.description[i18n.language]}
                  </div>
                  {
                    currentJournal.download.length > 0 && (
                      <a href={`${currentJournal.download[0].original.replace('http://', 'https://')}`} target="_blank" download>
                        <div className='details-btn' >
                          {i18n.t('post.details')}
                        </div>
                      </a>
                    )
                  }
                </div>
              </div>
              <hr />
              <div className='journal-list'>
                {
                  journalList.map((journal, key) => {
                    return (
                      <div className='card btn' key={key} onClick={navigate.bind(this, `/journal-detail?id=${journal.id}`)}>
                        <div className='image'>
                          <img src={journal.image[0].original.replace('http://', 'https://')} alt="" />
                        </div>
                        <div>
                          <div className='title'>
                            {journal.title[i18n.language]}
                          </div>
                          <div className='details-btn'>
                            {i18n.t('post.details')}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='right-container'>
              <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
