import React from 'react'
import { inject, observer } from 'mobx-react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { navigate } from 'gatsby-link'

@inject('initDataStore')
@observer
@withTranslation()

class News extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClick = (redirectUrl) => {
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    }
  }

  render () {
    const { handleClick } = this
    const { i18n, initDataStore } = this.props
    const partnerList = initDataStore.allContents.informations.partner
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='container flex-row space-between'>
          {/* <div className='left-container'> */}
          <div className='link-exchange'>
            <div className='link-exchange-list'>
              {
                partnerList.map((partner, key) => {
                  return (
                    <div className='card btn' key={key} onClick={handleClick.bind(this, partner.redirectUrl)}>
                      <div className='image-container'>
                        <div className='image'>
                          <img src={partner.image[0].thumbnail.replace('http://', 'https://')} alt="" />
                        </div>
                      </div>
                      <div className='company-name'>
                        {partner.companyName[i18n.language]}
                      </div>
                      <div className='description truncate-overflow'>
                        {partner.description[i18n.language]}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* </div> */}
          {/* <div className='right-container'>
            <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
          </div> */}
        </div>
      </>
    )
  }
}

export default News
