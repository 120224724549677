import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS, trimHtmlTag, truncateString } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import { navigate, Link } from 'gatsby'
import { Pagination } from '@material-ui/lab'

@inject('initDataStore')
@observer
@withTranslation()

class Test extends React.Component {
  constructor (props) {
    super(props)
    const parsed = queryString.parse(window.location.search)
    const searchText = parsed.text
    this.state = {
      searchText: searchText,
      postType: 'ALL',
      category: 'ALL',
      offset: 0,
      limit: 20,
      list: [],
      total: 0
    }
  }

  componentDidMount () {
    this.searchPost()
  }

  searchPost = async () => {
    const { initDataStore } = this.props
    const { searchText, postType, category, offset, limit, list } = this.state
    const data = await initDataStore.searchPostList(searchText, postType, category, offset, limit);

    const dataList = data.data;
    // toJS(data)
    this.setState({
      // offset: offset + limit,
      list: [...dataList],
      total: parseInt(data.totalLength[0].count)
    })
  }

  handleClick = (url, redirectUrl) => {
    if (redirectUrl) {
      window.open(redirectUrl, "_blank")
    } else {
      navigate(url)
    }
  }

  offsetAction = async (event, page) => {

    const { initDataStore } = this.props
    const offset = (page - 1) * this.state.limit
    // console.log({offset});

    const data = await initDataStore.searchPostList(this.state.searchText, this.state.postType, this.state.category, offset, this.state.limit);
    const dataList = data.data;

    this.setState(
      { 
        offset: offset,
        list: [...dataList],
        total: parseInt(data.totalLength[0].count)
      });
  }
  getPath = (type) => {
    let path
    // toJS(type)
    switch (type) {
      case 'EVENT':
        path = '/event/detail'
        break;
      case 'EXHIBITION':
        path = '/exhibition/detail'
        break;
      case 'MARKET_INFORMATION':
        path = '/market-information/detail'
      default:
        path = '/post/detail'
        break;
    }
    return path
  }

  render () {
    const { handleClick, getPath, offsetAction } = this
    const { i18n, initDataStore } = this.props
    const { searchText, list } = this.state
    const jobOpportunitiesList = list
    // toJS(jobOpportunitiesList)
    if (!jobOpportunitiesList) {
      return <></>
    }
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='member-news-list'>
              {
                jobOpportunitiesList.map((post, key) => {
                  const path = getPath(post.type)
                  return (
                    <div key={key}>
                      <div className='card post' onClick={handleClick.bind(this, `${path}?id=${post.id}`, post.redirectUrl)}>
                        <div className='flex-column'>
                          <div className='date'>
                            {
                              post.displayDateTime &&
                              moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                            }
                          </div>
                          <div className='title'>
                            {post.title[i18n.language]}
                          </div>
                          <div className='description'>
                            {truncateString(trimHtmlTag(post.content.en), 200)}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
              {
                this.state.total > 0 && (
                  <div className='pagination'>
                    <Pagination
                      count={Math.ceil(this.state.total / this.state.limit)}
                      page={Math.ceil((this.state.offset / this.state.limit) + 1)}
                      onChange={offsetAction}
                    />
                  </div>
                )
              }
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
          </div>
        </div>
      </>
    )
  }
}

export default Test
