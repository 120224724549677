import apiClient from '@src/services/apiClient'
import { searchMemberListPath } from '@hkpca-web/config/apiPaths'

export const searchMemberList = (searchText, memberBusinessNature, memberCity, memberLocation, offset, limit) => {
  return apiClient.post(
    searchMemberListPath,
    {
      searchText,
      memberBusinessNature,
      memberCity,
      memberLocation,
      offset,
      limit
    }
  )
}
