import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby-link'
import { withTranslation } from 'react-i18next'

import CloseIcon from '@material-ui/icons/Close';
import Wowbook from '@hkpca-web/components/viewers/Wowbook'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null,
      isReady: false,
      year: 'ALL',
      showModal: false,
      pdfUrl: "",
      desc: "",
      download: ""

    }
  }


  componentDidMount () {
    const parsed = queryString.parse(window.location.search)

    if (parsed.id) {
      this.setState({
        id: parseInt(parsed.id),
        isReady: true
      })
    } else {
      const { initDataStore } = this.props
      const firstItem = initDataStore.allContents.informations.journal[0]

      this.setState({
        id: firstItem.id,
        isReady: true,
      })
    }
  }

  componentDidUpdate () {
    const parsed = queryString.parse(window.location.search)
    const postId = parseInt(parsed.id)
    if (postId && this.state.id !== postId) {
      this.setState({
        id: postId,
        isReady: false
      }, () => {
        this.setState({
          isReady: true
        })
      })
    }
  }

  getYearOptionList = () => {
    const { initDataStore, i18n } = this.props
    const journalList = initDataStore.allContents.informations.journal
    let yearList = []
    journalList.forEach((journal) => {
      const year = moment(journal.date).format('YYYY')
      if (!yearList.includes(year)) {
        yearList.push(year)
      }
    })
    const yearOptionList = yearList.map((year) => {
      return {
        label: {
          en: year,
          tc: year,
          sc: year
        }, value: year
      }
    })
    return yearOptionList
  }

  getJournalList = () => {
    const { initDataStore } = this.props
    const { year } = this.state
    let journalList = []
    if (year === 'ALL') {
      journalList = initDataStore.allContents.informations.journal.filter((journal) => journal.id !== this.state.id)
    } else {
      journalList = initDataStore.allContents.informations.journal.filter((journal) => {
        return moment(journal.date).format('YYYY') === year && journal.id !== this.state.id
      })
    }

    return journalList
  }

  handleChange = (value) => {
    this.setState({ year: value.categories })
  }

  closeModal = (url) => {
    this.setState({ showModal: false})
  }

  handleClick = ( download, desc, undownloadable ) => {    
    let pdfUrl = download[0].original.replace('http://', 'https://')
    let link = undownloadable === true ? '' : download
    if(desc){
      this.setState({ showModal: true, pdfUrl: pdfUrl, desc: desc.replace(/\n/g,'|'), download: link})
    } else {
      this.setState({ showModal: true, pdfUrl: pdfUrl, desc: '', download: link})
    }
  }

  render () {
    const { initDataStore, i18n } = this.props
    const { handleChange, getYearOptionList, getJournalList, handleClick, closeModal  } = this
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    if (!this.state.isReady) {
      return (<></>)
    }
    // const journalList = initDataStore.allContents.informations.journal
    // const journalList = getJournalList()
    // toJS(journalList)
    const currentJournal = initDataStore.allContents.informations.journal.find((journal) => journal.id === this.state.id)
    const otherJournalList = getJournalList()
    // toJS(journalList)
    // toJS(this.state.id)
    const yearOptionList = getYearOptionList()
    toJS(yearOptionList)

    return (
      <>
        {this.state.showModal && <Wowbook file={this.state.pdfUrl} desc={this.state.desc} download={this.state.download} lang={i18n.language} />}
        {this.state.showModal && <div style={{position:'fixed',right:10,top:10,zIndex:9999,color:'white'}} onClick={closeModal.bind(this)}><CloseIcon /></div> }

        <div className='journal-container detail'>
          {/* <div className='filter'>
            <div className='title'>Year</div>
          </div> */}
          <div className='responsive-container'>
            <div className='left-container'>
              <div className='filter flex-row align-items-center'>
                <div className='title'>{i18n.t('other.categories')}</div>
                <div className='category-picker'>
                  <CategoryPicker
                    name='categories'
                    optionList={yearOptionList}
                    value={this.state.year}
                    onSubmit={handleChange}
                    hasNoneOption={false}
                    hasAllOption={true}
                    language={i18n.language}
                  />
                </div>
              </div>

              <div className='journal-detail'>
                <div className='image'>
                  <img src={currentJournal.image[0].original.replace('http://', 'https://')} alt="" />
                </div>
                <div className='detail'>
                  <div className='title'>
                    {currentJournal.title[i18n.language]}
                  </div>
                  <div className='description'>
                    {currentJournal.description[i18n.language]}
                  </div>
                  {
                    currentJournal.download.length > 0 && (
                      <div className='btn-container' onClick={handleClick.bind(this, currentJournal.download, currentJournal.toc[i18n.language], currentJournal.isUndownloadable)} >
                        <div className='details-btn' >
                            {i18n.t('post.details')}
                          </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <hr />
              <div className='journal-list'>
                {
                  otherJournalList.map((journal, key) => {
                    return (
                      <a>
                        <div className='card'>
                          {/* onClick={navigate.bind(this, `/journal-detail?id=${journal.id}`)} */}
                          <div className='image'>
                            <img src={journal.image[0].original.replace('http://', 'https://')} alt="" />
                          </div>
                          <div>
                            <div className='title'>
                              {journal.title[i18n.language]}
                            </div>
                            {
                              journal.download.length > 0 && (
                                journal.toc ? (
                                  <div className='btn-btn' onClick={handleClick.bind(this, journal.download, journal.toc[i18n.language], journal.isUndownloadable)}>
                                    <div className='details-btn'>
                                      {i18n.t('post.details')}
                                    </div>
                                  </div>
                                ) : (
                                  <div className='btn-btn' onClick={handleClick.bind(this, journal.download, journal.toc, journal.isUndownloadable)}>
                                    <div className='details-btn'>
                                      {i18n.t('post.details')}
                                    </div>
                                  </div>
                                )
                              )
                            }
                          </div>
                        </div>
                      </a>
                    )
                  })
                }
              </div>
            </div>
          
            <div className='right-container'>
              <LatestPostList
                newsList={newsList.slice(0, 3)}
                eventList={eventList.slice(0, 3)}
                marketInformationList={marketInformationList.slice(0, 3)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
