import React from 'react'
import TextField from '@material-ui/core/TextField'
// import { observable, action, toJS } from 'mobx'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

const BootstrapInput = withStyles((theme) => ({
  root: {

  },
  input: {
    fontSize: '14px',
    padding: '10px 12px',
    // height: '36px',
    // lineHeight: '36px',
    backgroundColor: '#fff',
    // opacity: 0.5,
    // border: '1px solid #707070',
    borderRadius: '4px',
    transition: 'background-color 0.5s ease',
    // transition: 'box-shadow 0.5s ease',
    '&:focus': {
      // backgroundColor: '#ebedf3'
      // boxShadow: '0 0 0 0.1rem #707070',
      // backgroundColor: '#000000',
      // opacity: 0.3,
    },
    '&::placeholder': {
      fontSize: '14px',
      // color: 'blue'
    }
  },
  // notchedOutline: {
  //   borderWidth: '0px',
  //   borderColor: '#f3f6f9 !important'
  // }
}))(InputBase)

const styles = {
  root: {
  },
  inputContainer: {
    width: '100%',
    border: '1px solid #707070',
    borderRadius: '4px',
    padding: '1px',
  },
  inputAdornment: {
    backgroundColor: '#fff',
    // opacity: 0.5,
    height: '100%',
    padding: '10px',
    margin: 0,
    cursor: 'pointer'
  },
  icon: {
    // fontSize: 16,
    color: '#707070',
    height: '17px'
  }
};

@withStyles(styles)

class CustomTextField extends React.Component {

  constructor (props) {
    super(props)
    const { value } = this.props
    const defaultValue = value
    this.state = {
      value: defaultValue
    }
  }

  handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      this.props.onSubmit({ searchText: this.state.value })
    }
  }

  handleChange = (event) => {
    const { isFormik } = this.props
    let value = event.target.value
    if (isFormik) {
      const name = this.props.field.name
      if (this.props.type === 'number') {
        value = parseInt(value)
      }
      // console.log(value)
      const setFieldValue = this.props.form.setFieldValue
      setFieldValue(name, value)
    }

    // const setFieldValue = this.props.setFieldValue
    // console.log(name)
    // setTimeout(() => {
    //   setValues('email', 'tetstestets')
    // }, 500)
    // console.log(toJS(this.props.form.values))
    // console.log(value)
    // console.log(this.props.form.handleChange)
    this.setState({ value: value })
    // console.log(this.props)
    // setFieldValue(name, 'tetstestets')
  }

  getIcon = () => {
    const { iconName, classes } = this.props
    let component
    switch (iconName) {
      case 'search':
        component = <SearchIcon className={classes.icon} />
        break;

      default:
        break;
    }
    return component
  }

  render () {
    const { handleChange, getIcon, handlePressEnter } = this
    const { label, type, placeholder, classes, isFormik, onChange, onSubmit } = this.props
    const { value } = this.state
    const icon = getIcon()
    // console.log(type)
    // console.log(classes)

    return (
      <div className={classes.inputContainer}>
        <BootstrapInput
          type={type}
          variant='outlined'
          // margin='normal'
          defaultValue={value}
          required
          fullWidth
          // label={label}
          // name='email'
          // autoComplete='email'
          placeholder={placeholder}
          onChange={handleChange}
          onKeyPress={handlePressEnter}
          endAdornment={
            <InputAdornment position="start" className={classes.inputAdornment} onClick={onSubmit.bind(this, { searchText: value })}>
              {icon}
            </InputAdornment>
          }
        />
      </div>
    )
  }
}

export default CustomTextField
