import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { toJS } from '@src/utils/commonUtil'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { navigate } from 'gatsby-link'
import Skeleton from '@material-ui/lab/Skeleton'
import { getPostDetailPath } from '@src/utils/hkpcaUtil'

@withTranslation()

class News extends React.Component {
  constructor (props) {
    super(props)
  }

  handleClick = (postId, postType, redirectUrl) => {
    const postDetailPath = getPostDetailPath(postType)
    const url = `${postDetailPath}?id=${postId}`
    if (redirectUrl) {
      // navigate(redirectUrl)
      window.open(redirectUrl, "_blank")
    } else {
      navigate(url)
    }
  }

  render () {
    const { handleClick } = this
    const { i18n, newsList } = this.props
    return (
      <>
        <div className='news-section' id='news-section'>
          <div className='container'>
            <div className='section-title'>{i18n.t('home.latestNewsTitle')}</div>
            <div className='news-list'>
              {
                newsList.map((post, key) => {
                  // toJS(news)
                  // toJS(post.displayDateTime.split('.')[0]+"Z")
                  // toJS(moment(post.displayDateTime).format('YYYY-MM-DD'))
                  return (
                    <div key={key}>
                      {/* <Slide direction="up" in={isTriggerNewsSection} mountOnEnter unmountOnExit> */}
                      <div className='card btn' onClick={handleClick.bind(this, post.id, post.type, post.redirectUrl)}>
                        <div className='image'>
                          {
                            post.icon && post.icon[0]
                              ?
                              <img src={post.icon[0].thumbnail.replace('http://', 'https://')} alt="" />
                              :
                              <Skeleton
                                animation={false}
                                variant="rect"
                                height={500}
                              />
                          }
                        </div>
                        <div className='content'>
                          <div className='date'>
                            {
                              post.displayDateTime &&
                              moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                            }
                          </div>
                          <div className='title'>
                            {post.title[i18n.language]}
                          </div>
                        </div>
                      </div>
                      {/* </Slide> */}
                    </div>
                  )
                })
              }
            </div>
            <div className='view-all-button' onClick={navigate.bind(this, '/news')}>{i18n.t('home.viewAll')}</div>
          </div>
        </div>
      </>
    )
  }
}

export default News
