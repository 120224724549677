import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import CustomImageGallery from '@hkpca-web/components/viewers/CustomImageGallery'
import CustomPhotoswipeGallery from '@hkpca-web/components/viewers/CustomPhotoswipeGallery'
import { t, toJS, trimHtmlTag } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { navigate } from 'gatsby'
import envConfig from '@src/configs/envConfig'
import { withTranslation } from 'react-i18next'


@inject('initDataStore')
@observer
@withTranslation()

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    this.setState({ id: parseInt(parsed.id) })
  }

  render () {
    const { i18n, initDataStore } = this.props
    const news = initDataStore.allContents.informations.post.find((post) => post.id === this.state.id)
    if (!this.state.id) {
      return (<></>)
    }
    return (
      <>
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='post-detail'>
              <div className='back-button' onClick={() => navigate(-1)}>{`< ${i18n.t('other.back')}`}</div>
              <div className='title'>{news.title[i18n.language]}</div>
              <div className='content'>
                {
                  // <CustomImageGallery imageList={news.image} />
                  <CustomPhotoswipeGallery imageList={news.image} />
                }
                {
                  trimHtmlTag(news.content.en) !== '' &&
                  <div className='rich-editor' dangerouslySetInnerHTML={{ __html: news.content.en }} />
                }
                <div className='download flex-row'>
                  <div>{i18n.t('post.download')}: </div><div><a href={`${news.download[0].original.replace('http://', 'https://')}`} target="_blank" download>Reply Form</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList
              newsList={initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')}
              eventList={initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')}
              marketInformationList={initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')}
            />
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
