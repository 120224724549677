import React from 'react'
import { navigate } from "gatsby"
import { toJS } from '@src/utils/commonUtil'
import i18next from 'i18next'
import { withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

@inject('initDataStore')
@observer
@withTranslation()


class Menu extends React.Component {
  constructor (props) {
    super(props)
  }
  render () {
    const { i18n, menuList, footerMenuList, initDataStore } = this.props
    const menu = menuList.find((menu) => menu.level === 1)
    menu.level2 = menuList.filter((menu) => menu.level === 2)
    menu.level2.forEach((level2item, key) => {
      menu.level2[key]['level3'] = menuList.filter((item) => level2item.identifier === item.parentIdentifier)
    })
    const pageList = initDataStore.allContents.informations.page.filter((page) => page.customPostType !== 'SYSTEM')
    return (
      <>
        <div className='footer-menu'>
          <div className='container'>
            <div className='top-menu'>
              {
                menu.level2.map((level2item, key) => {
                  return (
                    <div key={key}>
                      <div className='layer1-item' onClick={navigate.bind(this, `${level2item.path}`)}>{level2item.title[i18next.language]}</div>
                      <div>
                        {
                          level2item.identifier === 'event' &&
                          pageList.map((page, key2) => {
                            return (
                              <div key={key2} className='layer2-item' onClick={navigate.bind(this, `/custom-page/?identifier=${page.identifier}`)}>{page.title[i18n.language]}</div>
                            )
                          })
                        }
                        {
                          level2item.level3.map((level3item, key2) => {
                            return (
                              <div key={key2} className='layer2-item' onClick={navigate.bind(this, `${level3item.path}`)}>{level3item.title[i18next.language]}</div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <hr />
            <div className='bottom-menu'>
              {
                footerMenuList.map((item, key) => {
                  return (
                    <div key={key}>
                      <div className='layer1-item' onClick={navigate.bind(this, `${item.path}`)}>{item.title[i18next.language]}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Menu
