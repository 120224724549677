import React, { Fragment } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { enUS, zhCN } from 'date-fns/locale'
import { withStyles } from '@material-ui/core/styles'

const ErrorDatePicker = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid rgb(255, 0, 0) !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid rgb(255, 0, 0) !important',
    },
  },
})(KeyboardDatePicker)

class CustomDatePicker extends React.Component {
  constructor (props) {
    super(props)
    const value = this.props.initialValue
    let convertedValue = null
    console.log(value)

    const timestamp = Date.parse(value)
    if (isNaN(timestamp) === false) {
      convertedValue = new Date(value).toISOString()
    }

    this.state = {
      value: convertedValue
    }
  }

  handleChange = (value) => {
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue

    const timestamp = Date.parse(value)
    if (isNaN(timestamp) === false) {
      const convertedValue = new Date(value).toISOString()
      setFieldValue(name, convertedValue)
      this.setState({ value: convertedValue })
    } else {
      this.setState({ value: value })
    }
  }

  render () {
    const { handleChange } = this
    const { showError, label, language = 'en' } = this.props
    const { value } = this.state
    const name = this.props.field.name
    const localeMap = {
      en: enUS,
      tc: zhCN,
      sc: zhCN
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[language]}>
        <Fragment>
          {
            (showError && !value)
              ? <ErrorDatePicker
                openTo='year'
                label={label}
                format={'yyyy-MM-dd'}
                placeholder='YYYY-MM-DD'
                value={value}
                onChange={handleChange}
                clearable
                fullWidth
                inputVariant='standard'
              />
              : <KeyboardDatePicker
                openTo='year'
                label={label}
                format={'yyyy-MM-dd'}
                placeholder='YYYY-MM-DD'
                value={value}
                onChange={handleChange}
                clearable
                fullWidth
                inputVariant='standard'
              />
          }
        </Fragment>
      </MuiPickersUtilsProvider>
    )
  }
}

export default CustomDatePicker
