import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import {
  FormControl,
} from '@material-ui/core'

const styles = {
  input: {
    fontSize: '14px',
    padding: '10px 12px',
    backgroundColor: '#f2f2f2',
    transition: 'background-color 0.5s ease',
    '&::placeholder': {
      fontSize: '14px',
    }
  },
  error: {
    fontSize: '14px',
    padding: '10px 12px',
    backgroundColor: '#f2f2f2',
    borderColor: 'red',
    border: '1px solid red',
    transition: 'background-color 0.5s ease',
    '&::placeholder': {
      fontSize: '14px',
    }
  }
}

@withStyles(styles)

class CustomTextArea extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      value: defaultValue
    }
  }

  handleChange = (event) => {
    const name = this.props.field.name
    let value = event.target.value
    if (this.props.type === 'number') {
      value = parseInt(value)
    }
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, value)
  }

  render () {
    const { handleChange } = this
    const { errors, isFormik = true, type, placeholder, classes } = this.props
    const name = this.props.field.name
    const { value } = this.state

    return (
      <FormControl variant='outlined' fullWidth>
        <InputBase
          type={type}
          variant='outlined'
          value={value}
          fullWidth
          placeholder={placeholder}
          multiline
          rows={6}
          onChange={isFormik ? handleChange : ''}
          className={(errors && errors[name]) ? classes.error : classes.input}
        />
      </FormControl>
    )
  }
}

export default CustomTextArea
