import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS, trimHtmlTag, truncateString } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import { navigate, Link } from 'gatsby'
import { withTranslation } from 'react-i18next'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { initDataStore, i18n } = this.props
    const formDownloadList = initDataStore.allContents.informations.formDownload
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='responsive-container'>
          <div className='left-container'>
            <div className='form-download-list'>
              {
                formDownloadList.map((post, key) => {
                  return (
                    <div key={key}>
                      <div className='card post'>
                        <div className='flex-column'>
                          <div className='date'>
                            {
                              post.displayDateTime &&
                              moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                            }
                          </div>
                          <div className='title'>
                            {
                              post.file[0] &&
                              <a href={post.file[0].original.replace('http://', 'https://')} target="_blank" download>
                                {post.title[i18n.language]}
                              </a>
                            }
                          </div>
                          <div className='description'>{post.description[i18n.language]}</div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className='right-container'>
            <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
          </div>
        </div>
      </>
    )
  }
}

export default Test
