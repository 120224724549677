import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { trimHtmlTag, toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

import CloseIcon from '@material-ui/icons/Close';


import Flipbook from '@hkpca-web/components/viewers/Flipbook'
import Wowbook from '@hkpca-web/components/viewers/Wowbook'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    
    this.state = {
      // theposition: 0,
      collapseStateList: [],
      showModal: false,
      pdfUrl: "",
      desc: "",
      download: ""
    }
  }

  componentDidMount () {
    const { list } = this.props
    // window.addEventListener('scroll', this.listenToScroll)
    this.initCollapseStateList(list)
  }

  initCollapseStateList = (list) => {
    const collapseStateList = Array.from({ length: list.length }, () => true)
    this.setState({
      collapseStateList: [...this.state.collapseStateList, ...collapseStateList],
    })
  }

  tooglePostDetail = (key) => {
    const collapseStateList = this.state.collapseStateList
    collapseStateList[key] = !collapseStateList[key]
    this.setState({
      collapseStateList: collapseStateList
    })
  }

  // componentWillUnmount () {
  //   // window.removeEventListener('scroll', this.listenToScroll)
  // }

  // listenToScroll = async () => {
  //   const winScroll = document.body.scrollTop || document.documentElement.scrollTop
  //   const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

  //   // const scrolled = winScroll / height
  //   // console.log(winScroll, height)

  //   if (height - winScroll < 2000) {
  //     await this.handleLoadContent()
  //   }

  //   // this.setState({
  //   //   theposition: scrolled,
  //   // })
  // }

  // handleLoadContent = async () => {
  //   window.removeEventListener('scroll', this.listenToScroll)
  //   const data = await this.props.loadContent()
  //   this.initCollapseStateList(data)
  //   if (data.length > 0) {
  //     window.addEventListener('scroll', this.listenToScroll)
  //   }
  // }

  closeModal = (url) => {
    this.setState({ showModal: false})
  
  }

  handleClick = (url, redirectUrl, download, desc) => {

    if (redirectUrl) {
      // navigate(redirectUrl)
      if(redirectUrl.includes(".pdf")!==false) {
        if(desc[this.props.i18n.language] && desc[this.props.i18n.language].split('=====').length>1) {
          console.log('have desc',desc[this.props.i18n.language].split('=====')[1])
          this.setState({ showModal: true, pdfUrl: redirectUrl, desc: desc[this.props.i18n.language].split('=====\n')[1].replace(/\n/g,'|'), download: download})
        } else {
          console.log('no desc')
          this.setState({ showModal: true, pdfUrl: redirectUrl, desc:'', download: download})
      } 
        }
        else {
        window.open(redirectUrl, "_blank")
      }

    } else {
      navigate(url)
    }
  }

  handleMRClick = (url, redirectUrl, download, toc,undownloadable ) => {
    let link = undownloadable === true ? '' : download
    if (redirectUrl) {
      // navigate(redirectUrl)
      if(redirectUrl.includes(".pdf")!==false) {
        if(toc) {
          this.setState({ showModal: true, pdfUrl: redirectUrl, desc: toc.replace(/\n/g,'|'), download: link})
        } else {
          console.log('no desc')
          this.setState({ showModal: true, pdfUrl: redirectUrl, desc:'', download: link})
        } 
      }
        else {
        window.open(redirectUrl, "_blank")
      }
    } else {
      navigate(url)
    }
  }


  render () {
    const { i18n, list, path } = this.props
    // toJS(path)
    const { handleClick, closeModal, tooglePostDetail, handleMRClick } = this
    const { collapseStateList } = this.state
    if (!collapseStateList) {
      return (<></>)
    }
    return (
      <>
        <div
          className='post-list'
        >
          {this.state.showModal && <Wowbook file={this.state.pdfUrl} desc={this.state.desc} download={this.state.download} lang={i18n.language}/>}
          {this.state.showModal && <div style={{position:'fixed',right:10,top:10,zIndex:9999,color:'white'}} onClick={closeModal.bind(this)}><CloseIcon /></div> }
          {
            list.map((post, key) => {
              let button 
              if(post.marketInformationTag[0] === "MARKETING_RESEARCH" ){
                if(post.display === false){
                  if(post.toc) {
                    button = <div className='read-all-button' onClick={handleMRClick.bind(this, `${path}?id=${post.id}`, post.redirectUrl, post.download, post.toc[i18n.language], post.isUndownloadable)}>{i18n.t('other.readAll')}</div>
                  } else {
                    button = <div className='read-all-button' onClick={handleMRClick.bind(this, `${path}?id=${post.id}`, post.redirectUrl, post.download, post.toc, post.isUndownloadable)}>{i18n.t('other.readAll')}</div>
                  }
                }
              } else {
                button = <div className='read-all-button' onClick={handleClick.bind(this, `${path}?id=${post.id}`, post.redirectUrl, post.download, post.description)}>{i18n.t('other.readAll')}</div>
              }
              if(post.isPublished){
                return (
                  <div className='post' key={key}>
                    <div className='card'>
                      {
                         (post.image.length > 0 && post.image[0]) || (post.icon.length > 0 && post.icon[0]) ? (
                          <div className='image'>
                            <img src={(post.image.length > 0 ? post.image[0].original.replace('http://', 'https://') : post.icon[0].original.replace('http://', 'https://'))} alt="" />
                          </div>
                        ) : null
                      }
                      <div className='content'>
                        <div className='title' onClick={handleClick.bind(this, `${path}?id=${post.id}`, post.redirectUrl, post.download, post.description)}>
                          {post.title[i18n.language]}
                        </div>
                        <div className='date'>
                          {
                            post.displayDateTime &&
                            moment(post.displayDateTime.split('.')[0] + "Z").format('YYYY-MM-DD')
                          }
                        </div>
                        <div className='description' onClick={handleClick.bind(this, `${path}?id=${post.id}`, post.redirectUrl, post.download, post.description)}>
                          {post.description[i18n.language].split('=====')[0]}
                        </div>
                        { button }
                      </div>
                    </div>
                    <hr />
                  </div>
                )
              }
            })
          }
        </div>
      </>
    )
  }
}

export default Test
