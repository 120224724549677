import React from 'react'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
// import { observable, action, toJS } from 'mobx'
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { toJS } from '@src/utils/commonUtil'

const BootstrapInput = withStyles((theme) => ({
  root: {

  },
  input: {
    padding: '10px 12px',
    minWidth: '160px',
    height: '20px',
    lineHeight: '20px',
    backgroundColor: '#fff',
    borderColor: '#707070',
    border: '1px solid #707070',
    // transition: 'background-color 0.5s ease',
    '&:focus': {
      border: '1px solid #707070',
      borderRadius: '4px'
    }
  }
}))(InputBase)

class CategoryPicker extends React.Component {

  constructor (props) {
    super(props)
    const { value } = this.props
    const defaultValue = value
    this.state = {
      value: defaultValue
    }
    // toJS(defaultValue)
  }

  handleChange = (event) => {
    const value = event.target.value
    this.setState({ value: value })
    const { onSubmit, name } = this.props
    if (onSubmit) {
      onSubmit({ [name]: value })
    }
  }

  render () {
    const { handleChange } = this
    const { language = 'en', hasNoneOption = false, hasAllOption = false, label, type, placeholder, optionList, classes } = this.props
    const { value } = this.state
    const allOption = {
      en: 'All',
      tc: '全部',
      sc: '全部'
    }
    return (
      <FormControl variant='outlined' fullWidth>
        <Select
          value={value}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {
            hasNoneOption && (
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
            )
          }
          {
            hasAllOption && (
              <MenuItem value='ALL'>
                {
                  language && (
                    allOption[language]
                  )
                }
              </MenuItem>
            )
          }
          {
            language && (
              optionList.map((option, key) => {
                return <MenuItem key={key} value={option.value}>{option.label[language]}</MenuItem>
              })
            )
          }
          {
            !language && (
              optionList.map((option, key) => {
                return <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
              })
            )
          }
        </Select>
      </FormControl>
    )
  }
}

export default CategoryPicker
