import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { t, toJS, getBreadcrumb } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
// import LatestPostList from '@hkpca-web/components/home/LatestPostList'
import CustomTextField from '@hkpca-web/components/inputs/CustomTextField'
import CustomPicker from '@hkpca-web/components/inputs/CustomPicker'
import CustomDatePicker from '@hkpca-web/components/inputs/CustomDatePicker'
import { Formik, Form, Field } from 'formik'
import {
  Button,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as yup from 'yup'

@withTranslation()
@inject('initDataStore')
@observer

class MemberForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      showError: false
    }
  }

  toogleDialogOpen = () => {
    this.setState({ open: !this.state.open })
  };

  handleValidateForm = () => {
    this.setState({ showError: true })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors, resetForm }) => {
    const { initDataStore } = this.props
    await initDataStore.submitEventForm(values)
    this.toogleDialogOpen()
  }

  refreshPage = async () => {
    window.location.reload()
  }

  render () {
    const { handleValidateForm, handleSubmitForm, toogleDialogOpen, refreshPage } = this
    const { i18n, initDataStore } = this.props
    const { open, showError } = this.state

    const schemaConfig = {
      eventName: yup.string().required(),
      joinDate: yup.date().required(),
      theNumberOfParticipants: yup.number().positive().integer().required(),
      contactPersonName: yup.string().required(),
      contactEmail: yup.string().email().required(),
      wechatId: yup.string().required(),
    }
    const validationSchema = yup.object().shape(schemaConfig)

    return (
      <>
        <div className='member-form'>
          <div className='container'>
            <Formik
              initialValues={initDataStore.eventForm}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            // enableReinitialize
            >
              {(formikProps) => {
                // console.log(touched)
                // console.log(formikProps.errors)
                // bindSubmitForm(formikProps.handleSubmitForm)
                return (
                  <Form>
                    <div className='section'>
                      {/* <div className='section-title'>{i18n.t('member.section2Title')}</div> */}
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.eventNameLabel')}</div>
                          <div className='field w500'>
                            <Field
                              name={'eventName'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.joinDateLabel')}</div>
                          <div className='field w500'>
                            <Field
                              name={'joinDate'}
                              showError={showError}
                              component={CustomDatePicker}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.theNumberOfParticipantsLabel')}</div>
                          <div className='field w260'>
                            <Field
                              name={'theNumberOfParticipants'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                              type='number'
                            // placeholder={i18n.t('member.companyNameEnglishPlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.contactPersonNameLabel')}</div>
                          <div className='field w260'>
                            <Field
                              name={'contactPersonName'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                        <div className='space' />
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.emailAddress')}</div>
                          <div className='field w440'>
                            <Field
                              name={'contactEmail'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='flex-row'>
                        <div className='flex-column'>
                          <div className='label'>{i18n.t('member.wechatId')}</div>
                          <div className='field w500'>
                            <Field
                              name={'wechatId'}
                              errors={showError ? formikProps.errors : false}
                              component={CustomTextField}
                            // placeholder={i18n.t('member.companyNameChinesePlaceholder')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {
                      showError && Object.keys(formikProps.errors).length > 0 && (
                        <div className='error-message'>{i18n.t('member.inputErrorMessage')}</div>
                      )
                    }
                    <div className='submit-button-container'>
                      <Button type='submit' className='submit-button' disableElevation variant='contained' color='primary' onClick={handleValidateForm}>{i18n.t('member.submitButtonText')}</Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <Dialog
            open={open}
            onClose={toogleDialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle id="alert-dialog-title">
              {i18n.t('other.dialogTitle')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {i18n.t('other.dialogSuccessMessage')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={refreshPage} color="primary">
                {i18n.t('other.dialogButtonText')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }
}
export default MemberForm
