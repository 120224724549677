// import * as R from 'ramda'
import axios from 'axios'
import envConfig from '@src/configs/envConfig'

const baseURL = envConfig.apiHost + envConfig.basePath

const apiClient = axios.create({
  baseURL: baseURL,
  timeout: envConfig.requestTimeout
})

apiClient.interceptors.response.use(
  response => {
    // console.log(response)
    return response.data
  },
  error => {
    if (error.response) {
      return error.response.data
    }
    // console.log(error.response)
    // console.log(error.response.data)
    return error
    // const errorString = ('' + error)
    // const regex = /error: ([\W\w]+)/gmi
    // const match = regex.exec(errorString)
    // const errorMsg = match[1]
    // const errorCode = errorMsg.replace(' ', '_').toUpperCase()
    // alert(errorMsg)
    // throw { code: errorCode, msg: errorMsg }
    // throw R.pathOr(
    //   { code: 'UNKNOWN_SERVER_ERROR' },
    //   ['response', 'data'],
    //   error
    // )
  }
)

export default apiClient
