import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import PostList from '@hkpca-web/components/templates/PostList'
import { trimHtmlTag, toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import Collapse from '@material-ui/core/Collapse'
import { navigate } from 'gatsby-link'
import { withTranslation } from 'react-i18next'
// import Pagination from 'material-ui-flat-pagination'
import { withStyles } from '@material-ui/core/styles'
import { Pagination } from '@material-ui/lab'

const styles = {
  textPrimary: {
    fontSize: 100
  }
};

@withStyles(styles)

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.init()
    this.loadContent(this.state.offset)
  }

  init = () => {
    const { initDataStore } = this.props
    const state = {
      list: null,
      offset: 0,
      limit: 20,
    }
    if (initDataStore.pathname === window.location.pathname) {
      state.offset = initDataStore.offset
    }
    const pathList = window.location.pathname.split('/')
    switch (pathList[1]) {
      case 'news':
        state.postTypeList = null
        state.basePath = '/news'
        state.detailPath = '/news/detail'
        state.postTypeKey = null
        state.postType = 'NEWS'
        break;
      case 'event':
        state.postTypeList = this.props.initDataStore.allContents.categories.eventType
        state.basePath = '/event'
        state.detailPath = '/event/detail'
        state.postTypeKey = 'eventTag'
        state.postType = 'EVENT'
        break;
      case 'exhibition':
        state.postTypeList = this.props.initDataStore.allContents.categories.exhibitionType
        state.basePath = '/exhibition'
        state.detailPath = '/exhibition/detail'
        state.postTypeKey = 'exhibitionTag'
        state.postType = 'EXHIBITION'
        break;
      case 'market-information':
        state.postTypeList = this.props.initDataStore.allContents.categories.marketInformationType
        state.basePath = '/market-information'
        state.detailPath = '/market-information/detail'
        state.postTypeKey = 'marketInformationTag'
        state.postType = 'MARKET_INFORMATION'
        break;
      default:
        state.postType = 'ALL'
        break;
    }
    switch (pathList[2]) {
      case 'seminar-training':
        state.category = 'SEMINAR_TRAINING'
        break;
      case 'other-activities':
        state.category = 'OTHER_ACTIVITIES'
        break;
      case 'exhibition':
        state.category = 'EXHIBITION'
        break;
      case 'iec-exhibition':
        state.category = 'IEC_EXHIBITION'
        break;
      case 'other-exhibition':
        state.category = 'OTHER_EXHIBITION'
        break;
      case 'market-news':
        state.category = 'MARKET_NEWS'
        break;
      case 'ep-policies':
        state.category = 'EP_POLICIES'
        break;
      case 'marketing-research':
        state.category = 'MARKETING_RESEARCH'
        break;
      case 'technical-article':
        state.category = 'TECHNICAL_ARTICLE'
        break;
      default:
        state.category = 'ALL'
        break;
    }
    return state
  }

  componentDidMount () {
    const { initDataStore } = this.props
    const { postType, postTypeKey, limit } = this.state
    const allPosts = initDataStore.allContents.informations.post.filter((post) => post.type === postType)
    let list = allPosts
    if (this.state.category !== 'ALL') {
      list = allPosts.filter((post) => post[postTypeKey] && post[postTypeKey].includes(this.state.category))
    }
    const total = list.length
    // console.log(total)
    list = list.slice(0, limit)
    this.setState({
      list: list,
      total: total
    })
  }

  handleChange = (value) => {
    const { basePath, postTypeList } = this.state
    const postType = postTypeList.find((type) => type.value === value['categories'])
    if (!postType) {
      navigate(`${basePath}`)
    } else {
      navigate(`${basePath}${postType.path}`)
    }
  }

  // scrollContent = async () => {
  //   const { initDataStore } = this.props
  //   const { postType, category, offset, limit } = this.state
  //   const data = await initDataStore.searchPostList('', postType, category, offset, limit)
  //   this.setState({
  //     offset: offset + limit,
  //     list: [...this.state.list, ...data]
  //   })
  //   return data
  // }

  loadContent = async (offset) => {
    const { initDataStore } = this.props
    const { postType, category, limit } = this.state
    const data = await initDataStore.searchPostList('', postType, category, offset, limit)
    this.setState({
      offset: offset,
      list: data.data
    })
    return data.data
  }

  offsetAction = async (event, page) => {
    const offset = (page - 1) * this.state.limit
    const { initDataStore } = this.props
    await initDataStore.markPostList(window.location.pathname, offset)
    await this.loadContent(offset)
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }

  render () {
    const { initDataStore, i18n, classes } = this.props
    const { tooglePostDetail, handleChange, loadContent, offsetAction } = this
    const { detailPath, basePath, postTypeKey, postTypeList, postType, category, collapseStateList, list } = this.state
    const { offset, limit, total } = this.state
    // The total number of pages.
    const count = Math.ceil(total / limit)
    // The current page.
    const page = Math.ceil(offset / limit) + 1
    // toJS(total)
    if (!list) {
      return (<></>)
    }
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKETING_RESEARCH')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    return (
      <>
        <div className='post-list-container'>
          <div className='responsive-container'>
            <div className='left-container'>
              {
                postTypeList && (
                  <div className='filter flex-row align-items-center'>
                    <div className='title'>{i18n.t('other.categories')}</div>
                    <div className='category-picker'>
                      <CategoryPicker
                        name='categories'
                        optionList={postTypeList}
                        value={this.state.category}
                        onSubmit={handleChange}
                        hasNoneOption={false}
                        hasAllOption={true}
                        language={i18n.language}
                      />
                    </div>
                  </div>
                )
              }
              <PostList path={detailPath} list={list} loadContent={loadContent} />
              {
                total > 0 && (
                  <div className='pagination'>
                    <Pagination
                      // shape="rounded"
                      count={count}
                      page={page}
                      onChange={offsetAction}
                      // showFirstButton
                      // showLastButton
                    />
                    {/* <Pagination
                      limit={limit}
                      offset={offset}
                      total={total}
                      onClick={offsetAction}
                      currentPageColor='primary'
                      otherPageColor='default'
                      classes={styles}
                    /> */}
                  </div>
                )
              }
            </div>
            <div className='right-container'>
              <LatestPostList
                newsList={newsList.slice(0, 3)}
                eventList={eventList.slice(0, 3)}
                marketInformationList={marketInformationList.slice(0, 3)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
