import React from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import { toJS } from '@src/utils/commonUtil'
// import renderHTML from 'react-render-html'
import moment from 'moment'
import CategoryPicker from '@hkpca-web/components/inputs/CategoryPicker'
import { navigate } from 'gatsby-link'
import { withTranslation } from 'react-i18next'

@withTranslation()
@inject('initDataStore')
@observer

class Test extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      year: 'ALL'
    }
  }

  getYearOptionList = () => {
    const { initDataStore, i18n } = this.props
    const surveyList = initDataStore.allContents.informations.survey
    let yearList = []
    surveyList.forEach((survey) => {
      const year = moment(survey.date).format('YYYY')
      if (!yearList.includes(year)) {
        yearList.push(year)
      }
    })
    const yearOptionList = yearList.map((year) => {
      return { label: year, value: year }
    })
    return yearOptionList
  }

  getSurveyList = () => {
    const { initDataStore } = this.props
    const { year } = this.state
    let surveyList = []
    if (year === 'ALL') {
      surveyList = initDataStore.allContents.informations.survey
    } else {
      surveyList = initDataStore.allContents.informations.survey.filter((survey) => {
        return survey.year === year
      })
    }
    return surveyList
  }

  handleChange = (value) => {
    this.setState({ year: value.categories })
  }

  render () {
    const { handleChange, getYearOptionList, getSurveyList } = this
    const { initDataStore, i18n } = this.props
    const surveyList = getSurveyList()
    const currentsurvey = surveyList[0]
    // toJS(currentsurvey)
    const newsList = initDataStore.allContents.informations.post.filter((post) => post.type === 'NEWS')
    const eventList = initDataStore.allContents.informations.post.filter((post) => post.type === 'EVENT')
    const marketInformationList = initDataStore.allContents.informations.post.filter((post) => post.type === 'MARKET_INFORMATION')
    const yearOptionList = initDataStore.allContents.categories.surveyYear
    // const yearOptionList = getYearOptionList()
    // console.log(yearOptionList)
    return (
      <>
        <div className='survey-container'>
          <div className='responsive-container'>
            <div className='left-container'>
              <div className='filter flex-row align-items-center'>
                <div className='title'>{i18n.t('other.categories')}</div>
                <div className='category-picker'>
                  <CategoryPicker
                    name='categories'
                    optionList={yearOptionList}
                    value={this.state.year}
                    onSubmit={handleChange}
                    hasNoneOption={false}
                    hasAllOption={true}
                    language={i18n.language}
                  />
                </div>
              </div>
              <div className='survey-list'>
                {
                  surveyList.map((survey, key) => {
                    return (
                      <div className='card btn' key={key} onClick={navigate.bind(this, `/survey-detail?id=${survey.id}`)}>
                        <div className='image'>
                          <img src={survey.image[0].original.replace('http://', 'https://')} alt="" />
                        </div>
                        <div>
                          <div className='title'>
                            {survey.title[i18n.language]}
                          </div>
                          <div className='details-btn'>
                            {i18n.t('post.details')}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='right-container'>
              <LatestPostList newsList={newsList} eventList={eventList} marketInformationList={marketInformationList} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Test
